import React, { useState } from 'react';
import IconPlay from '../../../Common/icons/IconPlay';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import VideoPopup from '../../../Common/popups/VideoPopup';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import CustomImage from '../../../Common/CustomImage';

const VideoHighligh = ({ item }) => {
  const [popupOpened, set_popupOpened] = useState(false);
  return (
    <div className="flex gap-[40px] flex-col mb-[16px] w-full">
      <div className="w-full  rounded-3xl aspect-[2/1] max-w-[100%]  overflow-hidden group relative lg:h-[636px]">
        <CustomImage
          isLCP={true}
          field={item?.item?.thumbnailImage}
          className="object-cover w-full h-full duration-300 group-hover:scale-110"
        />
        <div
          onClick={
            item?.item?.videoLink?.href
              ? void 0
              : () => {
                  set_popupOpened(true);
                }
          }
          className={`${item?.item?.videoLink?.href ? '' : 'cursor-pointer'} absolute inset-0 z-10`}
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 30.21%, rgba(0, 0, 0, 0.66) 67.19%)',
          }}
        ></div>
        {item?.item?.videoLink?.href && (
          <BtnStandard type="NoFillWhite" onClick={() => set_popupOpened(true)}>
            <IconPlay className="absolute z-[20] text-white duration-200 hover:text-taziz-green cursor-pointer h-14 w-14  lg:h-[120px] lg:w-[120px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
          </BtnStandard>
        )}
        <p className="absolute bottom-0 left-0 right-0 z-20 hidden px-10 pb-10 text-white typo-p-large lg:block">
          <Text field={item?.item?.videoTitle} />
        </p>
      </div>
      <p className="text-taziz-blue typo-p-large lg:hidden">
        <Text field={item?.item?.videoTitle} />
      </p>
      {popupOpened && (
        <VideoPopup
          videoTitle={item?.item?.videoTitle}
          imgField={item?.item?.thumbnailImage}
          videoLink={item?.item?.videoLink?.href}
          closePopup={() => set_popupOpened(false)}
        />
      )}
    </div>
  );
};

export default VideoHighligh;
