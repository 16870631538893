import React from 'react'

const IconFullScreen = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.44914 3.5817V1.8396H4.70703" stroke="currentColor" strokeWidth="0.435526" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.22266 5.32373V7.06584H2.96476" stroke="currentColor" strokeWidth="0.435526" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.44912 1.8396L4.27148 4.01723" stroke="currentColor" strokeWidth="0.435526" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.40029 4.88818L1.22266 7.06582" stroke="currentColor" strokeWidth="0.435526" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconFullScreen