import React, { useRef, useEffect } from 'react'
import mapboxgl from '!mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';

const Map = ({ lng = 0, lat = 0, zoom = 5, token = null, style = "mapbox://styles/omniadevelopment/clte21318003701qzhgps5ssa", companies = [] }) => {
    const map = useRef(null)
    const mapContainer = useRef(null);
    mapboxgl.accessToken = token ? token : process.env.REACT_APP_MAPBOX_TOKEN

    useEffect(() => {
        console.log(lng, lat)
        if (map.current) {
            map.current?.flyTo({
                zoom: zoom,
                center: [lng, lat],
                essential: true,
                duration: 2000
            })
        }
    }, [lng, lat, zoom])

    useEffect(() => {
        if (map.current) return
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: style,
            center: [lng, lat],
            zoom: zoom,
        });
        companies?.map((item) => {
            const marker = document.createElement('div');
            marker.id = `${item.id}`
            marker.className = `relative w-0 h-0`
            const newMarker = new mapboxgl.Marker(marker)
                .setLngLat([item.fields?.Lng?.value, item.fields?.Lat?.value])
                .addTo(map.current);
            marker.element = newMarker;
        });
    }, []);

    return (
        <div className="w-full h-full" dir="ltr" ref={mapContainer}>
        </div>
    )
}

export default Map