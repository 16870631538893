import React from 'react'

const IconClose = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8806 16L26.2806 7.61334C26.5316 7.36227 26.6727 7.02174 26.6727 6.66667C26.6727 6.3116 26.5316 5.97107 26.2806 5.72C26.0295 5.46893 25.689 5.32788 25.3339 5.32788C24.9788 5.32788 24.6383 5.46893 24.3872 5.72L16.0006 14.12L7.6139 5.72C7.36283 5.46893 7.02231 5.32788 6.66724 5.32788C6.31217 5.32788 5.97164 5.46893 5.72057 5.72C5.4695 5.97107 5.32845 6.3116 5.32845 6.66667C5.32845 7.02174 5.4695 7.36227 5.72057 7.61334L14.1206 16L5.72057 24.3867C5.5956 24.5106 5.49641 24.6581 5.42871 24.8206C5.36102 24.983 5.32617 25.1573 5.32617 25.3333C5.32617 25.5094 5.36102 25.6836 5.42871 25.8461C5.49641 26.0086 5.5956 26.1561 5.72057 26.28C5.84452 26.405 5.99199 26.5042 6.15447 26.5719C6.31695 26.6396 6.49122 26.6744 6.66724 26.6744C6.84325 26.6744 7.01753 26.6396 7.18001 26.5719C7.34248 26.5042 7.48995 26.405 7.6139 26.28L16.0006 17.88L24.3872 26.28C24.5112 26.405 24.6587 26.5042 24.8211 26.5719C24.9836 26.6396 25.1579 26.6744 25.3339 26.6744C25.5099 26.6744 25.6842 26.6396 25.8467 26.5719C26.0092 26.5042 26.1566 26.405 26.2806 26.28C26.4055 26.1561 26.5047 26.0086 26.5724 25.8461C26.6401 25.6836 26.675 25.5094 26.675 25.3333C26.675 25.1573 26.6401 24.983 26.5724 24.8206C26.5047 24.6581 26.4055 24.5106 26.2806 24.3867L17.8806 16Z" fill="currentColor" />
        </svg>
    )
}

export default IconClose