import React, { useState } from 'react';
import IconSearch from '../../../../Common/icons/IconSearchInput';
import IconArrowDown from '../../../../Common/icons/IconArrowDown';

const SearchControllers = ({ set_inputText, setQuery, inputText, setSort, sort, phrases }) => {
  const [selectedSort, set_SelectedSort] = useState(null);

  const [openSelection, setOpenSelection] = useState(false);

  // Function to handle form submission and updating the search query.
  const handleSearch = (e) => {
    e.preventDefault();
    const q = e.currentTarget?.q?.value?.trim();
    setQuery(q);
  };

  // Function to handle sorting selection from the dropdown.
  const handleSorting = (val) => {
    set_SelectedSort(phrases?.[val?.toLocaleLowerCase()]);
    setSort(val);
    setOpenSelection(!openSelection);
  };

  return (
    <div className="relative z-10 flex flex-col gap-6 md:gap-10 md:flex-row" data-aos="fade-up">
      {/* Search Form */}
      <form action="" onSubmit={handleSearch} className="relative">
        <input
          value={inputText}
          type="text"
          name="q"
          onChange={(e) => set_inputText(e.target.value)}
          className="px-4 py-3 pr-[48px] rtl:pl-[48px] rtl:pr-[16px] bg-white w-full  md:w-[547px] rounded-lg outline-none text-taziz-blue"
          placeholder={`${phrases?.['search']}`}
        />
        <button type="submit">
          <IconSearch className="w-6 h-6 text-pressed-grey absolute rtl:left-[16px] rtl:right-[unset] right-[16px] top-[12px]" />
        </button>
      </form>

      {/* Sort Dropdown */}
      <div
        className=" bg-white w-full md:w-auto flex-none _md:flex-[unset] _md:w-[343px] rounded-lg h-[48px] outline-none text-taziz-blue relative z-10 "
        style={openSelection ? { boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.04)' } : {}}
      >
        <div
          className="flex items-center justify-between w-full px-4 py-3 bg-white outline-none cursor-pointer rounded-xl text-taziz-blue"
          onClick={() => setOpenSelection(!openSelection)}
        >
          <span>{selectedSort ? selectedSort : phrases?.['newest']}</span>
          <IconArrowDown
            className={`${openSelection ? 'rotate-180' : ''} duration-200 ease-in-out w-6 h-6`}
          />
        </div>
        <div
          className={`absolute top-[43px] left-0 right-0 transition-all duration-200 ease-in-out z-10 ${
            openSelection ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
          }`}
        >
          {['Newest', 'Oldest'].map((el, i) => (
            <div
              key={`${i}_${el}`}
              className={`${
                sort === el ? 'bg-hover-grey' : 'bg-white'
              } w-full  outline-none cursor-pointer overflow-hidden  text-taziz-blue ${
                i == 1 && 'rounded-b-2xl'
              }`}
              onClick={() => handleSorting(el)}
            >
              <div className="w-full h-full px-4 py-3 hover:bg-hover-grey/60 ">{`${
                phrases?.[el?.toLocaleLowerCase()]
              }`}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchControllers;
