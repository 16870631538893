import React, { useRef } from 'react';
import { RichText, Image, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import IconCaret from '../../../Common/icons/IconCaret';
import { COLORS } from '../../../../utils/constants';

const PageBanner = ({ fields, params }) => {
  const firstSectionRef = useRef(null);

  const scrollToNextSection = () => {
    const firstSectionHeight = firstSectionRef.current.clientHeight;
    window.scrollTo({
      top: firstSectionHeight,
      behavior: 'smooth',
    });
  };

  return fields ? (
    <section
      ref={firstSectionRef}
      className={`PageBanner relative min-h-screen py-[120px] lg:py-[186px] flex flex-col justify-center ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
      style={{
        color: COLORS[params?.TextColor] || '#001A70',
        background: COLORS[params?.BackgroundColor] || '#FFFFFF',
      }}
    >
      {fields.VideoLink?.value?.href ? (
        <video
          src={fields.VideoLink?.value?.href}
          playsInline
          autoPlay
          muted
          loop
          className="absolute top-0 left-0 object-cover object-center w-full h-full"
        />
      ) : fields?.Image?.value?.src ? (
        <Image
          field={fields.Image}
          className="absolute top-0 left-0 object-cover object-center w-full h-full"
        />
      ) : null}
      {(fields.Image?.value?.src || fields.VideoLink?.value?.href) && (
        <div className="absolute top-0 left-0 z-10 w-full h-full bg-taziz-blue/50" />
      )}
      <BtnStandard
        extraClass="absolute left-0 right-0 z-20 w-8 h-8 !p-2 mx-auto rounded-full bg-taziz-blue bottom-10 !w-[32px]"
        type="PrimaryBlue"
        onClick={scrollToNextSection}
      >
        <IconCaret className="w-full h-full text-white rotate-90" />
      </BtnStandard>
      <div className="container relative z-20">
        <div className="max-w-[898px] w-full flex flex-col lg:gap-20 gap-10">
          <div data-aos="fade-up" className="flex items-center gap-2">
            <Image field={fields.Icon} className="w-10 h-10" />
            <div
              className="w-6 lg:w-10 h-[2px]"
              style={{ background: COLORS[params?.TextColor] || '#001A70' }}
            />
            <Text
              tag="p"
              className="w-[calc(100%-80px)] lg:w-[calc(100%-104px)] uppercase"
              field={fields.PreTitle}
            />
          </div>
          <Text tag="h1" data-aos="fade-up" className="uppercase font-[500]" field={fields.Title} />
          <RichText data-aos="fade-up" className="typo-p-large" field={fields.Description} />
          {fields?.Link?.value?.href && (
            <div data-aos="fade-up" className="w-fit">
              <Link field={fields?.Link}>
                <BtnStandard type="PrimaryWhite">
                  {fields?.Link?.value?.text}
                  <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
                </BtnStandard>
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'PageBannerComponent'} />
  );
};

export default PageBanner;
