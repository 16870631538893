import React from 'react';
import VideoHighlightSkeleton from '../../../Common/LoadingSkeletons/VideoHighlightSkeleton';
import VideoSkeleton from '../../../Common/LoadingSkeletons/VideoSkeleton';
import NewsHighlightSkeleton from '../../../Common/LoadingSkeletons/NewsHighlightSkeleton';
import NewsItemSkeleton from '../../../Common/LoadingSkeletons/NewsItemSkeleton';

const LoadingEffect = ({ sort, firstLoad }) => {
  return (
    <>
      {sort === 'GALLERY' ? (
        <div className="flex flex-col flex-wrap  md:flex-row justify-center md:justify-start gap-[24px] w-full">
          {firstLoad ? <VideoHighlightSkeleton /> : <VideoSkeleton />}
          <VideoSkeleton />
          <VideoSkeleton />
          <VideoSkeleton />
        </div>
      ) : sort === 'ARTICLES' ? (
        <div className="flex flex-col md:flex-row justify-center md:justify-start  gap-[24px] lg:gap-[40px] w-full flex-wrap">
          {firstLoad ? <NewsHighlightSkeleton /> : <NewsItemSkeleton />}
          <NewsItemSkeleton />
          <NewsItemSkeleton />
          <NewsItemSkeleton />
        </div>
      ) : (
        <div className="flex flex-col flex-wrap  md:flex-row justify-center md:justify-start gap-[24px]  lg:gap-[40px]  w-full">
          {firstLoad ? <NewsHighlightSkeleton /> : <NewsItemSkeleton />}
          <NewsItemSkeleton />
          <NewsItemSkeleton />
          <NewsItemSkeleton />
        </div>
      )}
    </>
  );
};

export default LoadingEffect;
