import React from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import IconUser from '../../../Common/icons/IconUser';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const ProfileCard = ({ data, renderImage = true }) => {
  return (
    <div className="flex flex-col gap-4 pb-4 lg:gap-6 rounded-2xl lg:rounded-3xl overflow-clip lg:pb-6">
      {renderImage && data.Image?.value?.src ? (
        <div className="aspect-[4/3] relative bg-tab-default-grey">
          <CustomImage
            field={data.Image}
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
          />
        </div>
      ) : data.Icon?.value?.src ? (
        <CustomImage field={data.Icon} className="w-10 h-10 mt-4 lg:mt-6" />
      ) : (
        <div className="mt-4 lg:mt-6 w-10 h-10 border-[2px] border-taziz-blue flex items-center justify-center rounded-full p-2">
          <IconUser className="w-6 h-6 text-taziz-blue" />
        </div>
      )}
      <Text tag="h5" className="uppercase" field={data.Title} />
      <Text tag="p" className="typo-p-large" field={data.SubTitle} />
      <RichText className="typo-p" field={data.Description} />
    </div>
  );
};

const ProfileCards = ({ fields, params ,rendering }) => {
  return fields ? (
    <section
      className={`ProfileCards relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
       <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'HeroCards','TwoColumnCards','ThreeColumnCards','ArrangeMethod'],
          'Cards -Root Tab',
          'Cards -Edit Root Tab'
        )}
      >
      <div className="container flex flex-col gap-10 lg:gap-20">
        <Text tag="h2" className="uppercase" field={fields.Title} />
        <div className="flex flex-col gap-4 lg:gap-10">
          {fields.HeroCards?.length > 0 && (
            <div className="grid grid-cols-1 gap-4 overflow-hidden md:grid-cols-2 lg:gap-10 rounded-2xl lg:rounded-3xl">
              <div className="aspect-[4/3] md:aspect-auto relative">
                <CustomImage
                  field={fields.HeroCards[0].fields?.Image}
                  className="absolute top-0 left-0 object-cover object-center w-full h-full"
                />
              </div>
              <div className="flex flex-col gap-4 py-4 lg:gap-6 lg:pe-10 lg:py-14">
                <Text tag="h4" className="uppercase" field={fields.HeroCards[0].fields?.Title} />
                <Text
                  tag="p"
                  className="typo-p-large"
                  field={fields.HeroCards[0].fields?.SubTitle}
                />
                <RichText className="typo-p" field={fields.HeroCards[0].fields?.Description} />
              </div>
            </div>
          )}
          {(fields.TwoColumnCards?.length > 0 || fields.ThreeColumnCards?.length > 0) && (
            <div
              className={`flex ${
                fields.ArrangeMethod?.value === 'TwoColumnsUp' ? 'flex-col' : 'flex-col-reverse'
              } gap-4 lg:gap-10`}
            >
              {fields.TwoColumnCards?.length > 0 && (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-10">
                  {fields.TwoColumnCards?.map((item, index) => (
                    <ProfileCard key={index} data={item.fields} renderImage={false} />
                  ))}
                </div>
              )}
              {fields.ThreeColumnCards?.length > 0 && (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-y-10 lg:gap-x-6">
                  {fields.ThreeColumnCards?.map((item, index) => (
                    <ProfileCard key={index} data={item.fields} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'ProfileCardsComponent'} />
  );
};

export default ProfileCards;
