import React from 'react'

const IconLinkedIn = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.6691 1.59998C2.05546 1.59998 1 2.64448 1 4.01804C1 5.36166 2.02492 6.43786 3.6065 6.43786H3.638C5.2829 6.43786 6.30588 5.36186 6.30588 4.01804C6.27488 2.64448 5.2829 1.59998 3.6691 1.59998ZM23 14.3192V22.3458H18.2847V14.8568C18.2847 12.9755 17.6027 11.6916 15.8959 11.6916C14.593 11.6916 13.8176 12.5568 13.4765 13.3938C13.3521 13.693 13.3201 14.1095 13.3201 14.5284V22.3458H8.60318C8.60318 22.3458 8.66671 9.66187 8.60318 8.34772H13.3196V10.3319C13.3151 10.3389 13.31 10.3462 13.3048 10.3534C13.2991 10.3615 13.2934 10.3695 13.2886 10.3771H13.3196V10.3319C13.9462 9.37946 15.0653 8.01887 17.5702 8.01887C20.6736 8.01882 23 10.0195 23 14.3192ZM5.99525 22.3458H1.28014V8.34779H5.99525V22.3458Z" fill="currentColor" />
        </svg>
    )
}

export default IconLinkedIn