import React, { useState } from 'react';
import IconPlay from '../../../Common/icons/IconPlay';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import VideoPopup from '../../../Common/popups/VideoPopup';
import CustomImage from '../../../Common/CustomImage';
import { formatTimestamp } from '../../../../utils/formatDate';

const NewsItem = ({ item, hasVideo, phrases }) => {
  const [popupOpened, set_popupOpened] = useState(false);
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;
  return (
    <a
      href={hasVideo ? void 0 : item?.item?.url}
      className={`flex flex-col flex-auto w-full md:w-[349px] md:max-w-[50%] lg:max-w-[349px] overflow-hidden transition-all h-[395px] duration-300 bg-white  group rounded-3xl hover:bg-hover-grey ${
        hasVideo ? '' : 'cursor-pointer'
      }`}
    >
      <div className="w-full h-[195px] overflow-hidden relative flex-none">
        {hasVideo && (
          <div
            onClick={
              item?.item?.videoLink?.href
                ? void 0
                : () => {
                    set_popupOpened(true);
                  }
            }
            className={`${
              item?.item?.videoLink?.href ? '' : 'cursor-pointer'
            } absolute inset-0 z-10`}
            style={{
              background:
                'linear-gradient(0deg, rgba(0, 26, 112, 0.50) 0%, rgba(0, 26, 112, 0.50) 100%)',
            }}
          ></div>
        )}
        {hasVideo ? (
          <CustomImage
            field={item?.item?.thumbnailImage}
            className="object-cover w-full h-full duration-300 group-hover:scale-110"
          />
        ) : (
          <CustomImage
            field={item?.item?.featuredImage}
            className="object-cover w-full h-full duration-300 group-hover:scale-110"
          />
        )}
        {hasVideo && item?.item?.videoLink?.href && (
          <BtnStandard type="NoFillWhite" onClick={() => set_popupOpened(true)}>
            <IconPlay
              className="absolute z-[30] text-white duration-200 hover:text-taziz-green h-14 w-14 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
              onClick={() => set_popupOpened(true)}
            />
          </BtnStandard>
        )}
      </div>
      <div className="flex flex-col justify-between gap-6 p-6 grow lg:gap-12">
        <p className="line-clamp-3 typo-p-large">
          {hasVideo ? (
            <Text field={item?.item?.videoTitle} />
          ) : (
            <Text field={item?.item?.headline} />
          )}
        </p>
        <div className="flex items-center gap-1.5 typo-p">
          <p tag="p" className="uppercase text-taziz-blue">
            {formatTimestamp(item?.item?.date?.value, language)}
          </p>
          {((hasVideo && phrases?.['Video']) || (!hasVideo && phrases?.['Article'])) && (
            <div className="text-taziz-blue">|</div>
          )}
          <div className="uppercase text-taziz-green">
            {hasVideo ? phrases?.['Video'] : phrases?.['Article']}
          </div>
        </div>
      </div>
      {popupOpened && hasVideo && (
        <VideoPopup
          videoTitle={item?.item?.videoTitle}
          imgField={item?.item?.thumbnailImage}
          videoLink={item?.item?.videoLink?.href}
          closePopup={() => set_popupOpened(false)}
        />
      )}
    </a>
  );
};

export default NewsItem;
