import React from 'react';
import useExperienceEditor from '../../hooks/useExperienceEditor';

const PlaceholderComponent = ({ title }) => {
    const inExperienceEditor = useExperienceEditor();
    return inExperienceEditor ? (
        <div className="w-full h-[200px] flex items-center justify-center bg-[#808080] opacity-50 text-white text-[30px] border">
            {' '}
            {title ? title : 'Placeholder Component'}{' '}
        </div>
    ) : null;
};

export default PlaceholderComponent;