import React from 'react';

const IconPlus = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : 'w-6 h-6 text-taziz-blue'}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0007 5.33334C16.737 5.33334 17.334 5.93029 17.334 6.66667V14.6667H25.334C26.0704 14.6667 26.6673 15.2636 26.6673 16C26.6673 16.7364 26.0704 17.3333 25.334 17.3333H17.334V25.3333C17.334 26.0697 16.737 26.6667 16.0007 26.6667C15.2643 26.6667 14.6673 26.0697 14.6673 25.3333V17.3333H6.66732C5.93094 17.3333 5.33398 16.7364 5.33398 16C5.33398 15.2636 5.93094 14.6667 6.66732 14.6667H14.6673V6.66667C14.6673 5.93029 15.2643 5.33334 16.0007 5.33334Z"
        fill="#00BFB2"
      />
    </svg>
  );
};

export default IconPlus;
