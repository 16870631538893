import React, { useEffect, useState } from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconPlay from '../../../Common/icons/IconPlay';
import VideoPopup from '../../../Common/popups/VideoPopup';
import CustomImage from '../../../Common/CustomImage';

const Video = ({ fields, params }) => {
  const [popupOpened, set_popupOpened] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return fields ? (
    <section
      className={`Video relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      }  ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div className="container">
        <div
          data-aos="fade-up"
          className="aspect-[1129/636] rounded-2xl lg:rounded-3xl overflow-hidden relative"
        >
          <CustomImage
            field={isMobile ? (fields.MobileThumbnailImage?.value?.src ? fields.MobileThumbnailImage : fields.ThumbnailImage) : fields.ThumbnailImage}
            className="object-cover object-center w-full h-full"
          />
          {fields.VideoLink?.value?.href && (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
              <BtnStandard type="NoFillWhite" onClick={() => set_popupOpened(true)}>
                <IconPlay className="w-[80px] h-[80px] lg:w-[120px] lg:h-[120px]" />
              </BtnStandard>
            </div>
          )}
          <Text
            tag="p"
            data-aos="fade-up"
            className="absolute bottom-0 left-0 hidden p-4 text-white lg:block typo-p-large"
            field={fields.VideoTitle}
          />
        </div>
        <Text tag="p" data-aos="fade-up" className="mt-6 lg:hidden" field={fields.VideoTitle} />
      </div>
      {popupOpened && (
        <VideoPopup
          videoLink={fields.VideoLink?.value?.href}
          closePopup={() => set_popupOpened(false)}
        />
      )}
    </section>
  ) : (
    <PlaceholderComponent title={'VideoComponent'} />
  );
};

export default Video;
