import React from 'react';
import useFontAlignmentFix from '../../../hooks/useFontAlignmentFix';

const BtnTab = ({ type = 'White', active = false, children, onClick }) => {
  const { needsFontAlignmentFix } = useFontAlignmentFix();
  const className = {
    White: `${
      !active ? 'bg-tab-default-grey text-white' : 'bg-white text-taziz-blue'
    } hover:bg-taziz-green hover:text-taziz-blue`,
    Blue: `${
      !active ? 'bg-taziz-blue-active text-taziz-blue' : 'bg-taziz-blue text-white'
    } hover:bg-taziz-green hover:text-taziz-blue`,
  };

  return (
    <button
      onClick={onClick}
      className={`${
        needsFontAlignmentFix ? 'items-end' : 'items-center'
      }  rounded-full px-4 py-2 flex justify-between items-center gap-[10px] typo-p ${
        className[type] ? className[type] : className['White']
      } transition-all duration-300`}
    >
      {children}
    </button>
  );
};

export default BtnTab;
