import { createSlice } from '@reduxjs/toolkit';

export const LangSlice = createSlice({
  name: 'lang',
  initialState: {
    lang: 'en',
  },
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload.lang;
    },
  },
});

export const { setLang } = LangSlice.actions;

export const getLang = (state) => state?.lang?.lang;

export default LangSlice.reducer;
