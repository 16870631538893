export const COLORS = {
  'taziz-blue': '#001A70',
  'taziz-blue-light': '#8993B6',
  'taziz-blue-active': '#9AA6CF',
  'pastel-blue': '#C9D3F5',
  'projects-blue': '#A2C9FD',

  'taziz-green': '#00BFB2',
  'dark-green': '#004F59',
  'contact-green': '#BAE3E1',
  'pastel-green': '#BCCED2',

  white: '#FFFFFF',
  'neutral-grey': '#F8F8F8',
  'media-grey': '#C8CBCC',
};

export const SEARCH_PAGE_SIZE = 5;
export const MEDIA_PAGE_SIZE = 4;
