import React, { useState, useEffect } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import IconCaret from '../../../Common/icons/IconCaret';
import useFontAlignmentFix from '../../../../hooks/useFontAlignmentFix';

const MediaDetailsBody = ({ params, rendering, isRtl, lang }) => {
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;
  const { device } = useFontAlignmentFix();
  const navigateBack = () => {
    if (typeof window !== 'undefined') window.location.href = `/${language}/media-center`;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth', // Enable smooth scrolling
    });
  };

  const [phrases, set_phrases] = useState(null);

  useEffect(() => {
    if (window?.phrases) set_phrases({ ...window.phrases });
    else set_phrases(null);
  }, []);

  return (
    <section
      className={`MediaDetailsBody py-10 lg:pb-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div className="container">
        <div
          data-aos="fade-up"
          className="w-full flex px-0 sm:px-10 md:px-16 lg:px-20 xl:px-[120px] 2xl:px-[160px] mb-10"
        >
          <BtnStandard type="NoFillWhite" onClick={navigateBack}>
            <IconCaret className="w-5 h-5 text-taziz-green scale-[-1] rtl:scale-100" />
            <div
              className={`${device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''}
                      uppercase text-taziz-green`}
            >
              {phrases?.['back']}
            </div>
          </BtnStandard>
        </div>
      </div>
      <Placeholder name="media-details" rendering={rendering} isRtl={isRtl} lang={lang} />
      <div data-aos="fade-up" className="flex justify-center pt-10 lg:pt-20">
        <BtnStandard type="PrimaryBlue" onClick={scrollToTop}>
          {phrases?.['back-to-top']}
          <IconArrowCircle className="w-5 h-5 -rotate-90" />
        </BtnStandard>
      </div>
    </section>
  );
};

export default MediaDetailsBody;
