// ErrorSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const ErrorSlice = createSlice({
  name: 'errors',
  initialState: {
    formErrors: [],
  },
  reducers: {
    setFormErrors: (state, action) => {
      state.formErrors = action.payload.formErrors;
    },
  },
});

export const { setFormErrors } = ErrorSlice.actions;

export const getFormErrors = (state) => state.errors.formErrors;

export default ErrorSlice.reducer;
