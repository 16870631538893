import React, { useEffect, useRef, useState } from 'react';
import { RichText, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { SwiperSlide, Swiper } from 'swiper/react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnTab from '../../../Common/buttons/BtnTab';
import IconLocationPin from '../../../Common/icons/IconLocationPin';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import Map from './Map';
import ReactDOM from 'react-dom/client';
import useFontAlignmentFix from '../../../../hooks/useFontAlignmentFix';

const CompanyCard = ({ item, isRtl, active }) => {
  const { device, language } = useFontAlignmentFix();
  return (
    <div
      id={`${item.id}_chiid`}
      dir={isRtl ? 'rtl' : 'ltr'}
      className={`font-sans absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-[230px] lg:w-[300px] px-4 py-6 flex flex-col gap-6 bg-white rounded-3xl transition-opacity duration-1000 ${
        active ? '' : 'opacity-0'
      }`}
    >
      <div className="flex flex-col gap-4">
        <IconLocationPin className="w-6 h-6" />
        <div className="flex flex-col gap-[11px]">
          <Text tag="p" className="typo-p-small-bold lg:!typo-p-large" field={item.fields?.Title} />
          <RichText className="typo-p-small-regular lg:!typo-p" field={item.fields?.Description} />
        </div>
      </div>
      {item.fields?.CTALink?.value?.href && (
        <Link field={item.fields?.CTALink} className="w-fit">
          <BtnStandard type="PrimaryBlue">
            <span className={`${device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''}`}>
              {item.fields?.CTALink?.value?.text}
            </span>
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
        </Link>
      )}
    </div>
  );
};

const Maps = ({ params, fields, isRtl }) => {
  const swiperRef = useRef('swiperRef');
  const [activeTab, set_activeTab] = useState(0);
  const { device, language } = useFontAlignmentFix();

  useEffect(() => {
    fields.SelectedItems.map((item, index) => {
      const target = document.getElementById(`${item.id}_chiid`);
      if (target) {
        if (activeTab === index) {
          target.classList.remove('opacity-0');
        } else {
          target.classList.add('opacity-0');
        }
      }
    });
    swiperRef.current?.swiper?.slideTo(activeTab);
  }, [activeTab]);

  useEffect(() => {
    fields.SelectedItems.map((item, index) => {
      const target = document.getElementById(item?.id);
      if (target) {
        const root = ReactDOM.createRoot(target);
        root.render(<CompanyCard item={item} isRtl={isRtl} active={index === 0} />);
      } else {
        console.log('target not found', item.id);
      }
    });
  }, [fields.SelectedItems]);

  return fields ? (
    <section
      className={`Maps relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div className="container flex flex-col gap-4">
        <div data-aos="fade-up" className="w-full">
          <Swiper
            ref={swiperRef}
            className="w-full"
            spaceBetween={10}
            navigation={false}
            slidesPerView={'auto'}
            dir={isRtl ? 'rtl' : 'ltr'}
          >
            {fields.SelectedItems.map((item, index) => (
              <SwiperSlide key={index} className="!w-fit">
                <BtnTab
                  type="Blue"
                  active={index === activeTab}
                  onClick={() => set_activeTab(index)}
                >
                  <span
                    className={`${device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''}`}
                  >
                    <Text field={item.fields.PreTitle} />
                  </span>
                </BtnTab>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          data-aos="fade-up"
          className="flex relative justify-center h-[348px] md:h-[500px] lg:h-[635px] rounded-2xl lg:rounded-3xl overflow-hidden bg-taziz-blue"
        >
          <div className="absolute top-0 left-0 w-full h-full">
            <Map
              lng={Number(fields.SelectedItems?.[activeTab]?.fields?.Lng?.value || 0)}
              lat={Number(fields.SelectedItems?.[activeTab]?.fields?.Lat?.value || 0)}
              token={fields.MapBoxAccessToken?.value}
              style={fields.MapBoxStyleUrl?.value}
              companies={fields.SelectedItems}
              zoom={11}
            />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'MapsComponent'} />
  );
};

export default Maps;
