import React from 'react';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import CustomImage from '../../../Common/CustomImage';
import {EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const LinksCards = ({ fields, params, rendering }) => {
  return fields ? (
    <section
      className={`LinksCards relative py-10 lg:py-[120px] ${params?.Variant === 'default' ? 'bg-neutral-grey' : 'bg-projects-blue'
        } ${params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
            ? '!pb-0'
            : params?.RemovePaddingFrom === 'both'
              ? '!py-0'
              : ''
        }`}
    >
   <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems'],
          'LinksCards -Root Tab',
          'LinksCards -Edit Root Tab'
        )}
      >
      <div className="flex flex-col gap-10 lg:gap-[60px]">
        <Text data-aos="fade-up" tag="h3" className="text-center uppercase" field={fields.Title} />
        <div data-aos="fade-up" className="w-full lg:max-w-[1440px] lg:px-24 xl:px-[156px] mx-auto">
          <Swiper
            resistance={true}
            centeredSlides={false}
            roundLengths={true}
            breakpoints={{
              0: {
                slidesPerView: '1.2',
                spaceBetween: 16,
                resistance: true,
              },
              480: {
                slidesPerView: 'auto',
                spaceBetween: 16,
                resistance: true,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
                resistance: true,
              },
            }}
          >
            {fields.SelectedItems?.slice(0, 3)?.map((item, index) => (
              
              <SwiperSlide
                key={index}
                className="relative w-[324px] md:min-w-[calc(33%-40px)] h-auto first:ms-4 first:sm:ms-6 first:md:ms-12 first:lg:ms-0 last:me-4 last:se:ms-6 last:me:ms-12 last:le:ms-0 rounded-3xl bg-white overflow-hidden"
              >
                 <EditFrame
               {...getEditFrameProps(
                 item.id || '',
                 ['Title', 'CTALink','Image','Icon'],
                 'LinksCards -Edit Card' + index,
                 'LinksCards -Edit Card' + index
               )}
             >
                {params?.Variant === 'default' && (
                  <CustomImage
                    className="absolute top-0 left-0 object-cover object-center w-full h-full"
                    field={item.fields?.Image}
                  />
                )}
                {params?.Variant === 'default' && (
                  <div className="absolute top-0 left-0 w-full h-full bg-taziz-blue/50" />
                )}
                <div className="relative z-10 flex flex-col gap-10 px-6 py-10">
                  <div className="flex flex-col gap-6">
                    <CustomImage className="w-10 h-10" field={item.fields?.Icon} />
                    <Text
                      tag="h5"
                      className={`uppercase ${params?.Variant === 'default' ? 'text-white' : 'text-taziz-blue'
                        }`}
                      field={item.fields?.Title}
                    />
                  </div>
                  {item.fields?.CTALink?.value?.href && (
                    <Link field={item.fields?.CTALink} className='w-fit'>
                      <BtnStandard
                        type={params?.Variant === 'default' ? 'PrimaryWhite' : 'PrimaryBlue'}
                      >
                        {item.fields?.CTALink?.value?.text}
                        <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
                      </BtnStandard>
                    </Link>
                  )}
                </div>
                </EditFrame>
              </SwiperSlide>
              
            ))}
          </Swiper>
        </div>
      </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'LinksCardsComponent'} />
  );
};

export default LinksCards;
