import React from 'react';
import { RichText, Image, Text } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import Badge from '../../../Common/Badge';
import CustomImage from '../../../Common/CustomImage';
import {EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const ImageAndContentCards = ({ fields, params,rendering }) => {
  return fields ? (
    <section
      className={`ImageAndContentCards relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
        <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems','Description'],
          'Cards -Root Tab',
          'Cards -Edit Root Tab'
        )}
      >

      <div className="container flex flex-col gap-10 lg:gap-20">
        <div className="flex flex-col gap-6 lg:gap-10 max-w-[897px]">
          <Text data-aos="fade-up" tag="h3" className="uppercase" field={fields.Title} />
          <RichText data-aos="fade-up" className="typo-p-large" field={fields.Description} />
        </div>
        <div
          data-aos="fade-up"
          className={`flex flex-col ${
            params.ImageAlignment === 'left' ? 'lg:flex-row' : 'lg:flex-row-reverse'
          } rounded-2xl lg:rounded-3xl overflow-hidden`}
        >
          <div className="w-full lg:w-[50%] lg:min-h-[677px]">
            <CustomImage
              className="w-full h-full lg:object-cover lg:object-center"
              field={fields.Image}
            />
          </div>
          <div className="w-full lg:w-[50%] p-6 lg:p-10 flex flex-col gap-8 lg:gap-10 justify-center bg-card-grey">
            {fields.SelectedItems.map((item, index) => (
               <EditFrame
               {...getEditFrameProps(
                 item.id || '',
                 ['Title', 'Description', 'SelectedItems'],
                 'Cards - Card-' + index,
                 'Cards-Edit Card-' + index
               )}
               >
              <React.Fragment>
                {index > 0 && (
                  <div data-aos="fade-up" className="w-full h-1 bg-white rounded-full" />
                )}
                <div key={index} className="flex flex-col gap-6 lg:gap-8">
                  <Text
                    data-aos="fade-up"
                    tag="h4"
                    className="uppercase"
                    field={item.fields?.Title}
                  />
                  <RichText
                    data-aos="fade-up"
                    className="typo-p-large"
                    field={item.fields?.Description}
                  />
                  {item.fields?.SelectedItems?.length > 0 && (
                    <div data-aos="fade-up" className="flex flex-wrap gap-4 lg:gap-2">
                      {item.fields.SelectedItems.map((subItem, subIndex) => (
                         <EditFrame
                         {...getEditFrameProps(
                           subItem.id || '',
                           ['Title'],
                           'Cards - subItem-' + subIndex,
                           'Cards-Edit subItem-' + subIndex
                         )}
                         >
                        <Badge key={subIndex} title={subItem.fields?.Title.value} type="blue" />
                        </EditFrame>
                      ))}
                    </div>
                  )}
                </div>
              </React.Fragment>
              </EditFrame>
            ))}
          </div>
        </div>
      </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'ImageAndContentCardsComponent'} />
  );
};

export default ImageAndContentCards;
