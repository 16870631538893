import React from 'react';

const IconCaret = ({ className }) => {
  return (
    <svg
      className={className ? className : 'w-6 h-6 text-taziz-blue'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6364 11.3636C15.9879 11.7151 15.9879 12.2849 15.6364 12.6364L9.63642 18.6364C9.28495 18.9878 8.7151 18.9878 8.36363 18.6364C8.01216 18.2849 8.01216 17.715 8.36363 17.3636L13.7272 12L8.36363 6.63637C8.01216 6.2849 8.01216 5.71505 8.36363 5.36358C8.7151 5.01211 9.28495 5.01211 9.63642 5.36358L15.6364 11.3636Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCaret;
