import React from 'react';

const IconSearch = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : 'w-6 h-6 text-taziz-blue'}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.7078 27.2925L22.449 21.035C24.2631 18.8572 25.1676 16.0638 24.9746 13.236C24.7815 10.4081 23.5057 7.76361 21.4125 5.85251C19.3193 3.9414 16.5698 2.91086 13.7362 2.97526C10.9025 3.03966 8.20274 4.19404 6.19851 6.19827C4.19429 8.20249 3.0399 10.9023 2.9755 13.7359C2.9111 16.5696 3.94164 19.319 5.85275 21.4122C7.76385 23.5054 10.4084 24.7813 13.2362 24.9743C16.064 25.1674 18.8574 24.2628 21.0353 22.4488L27.2928 28.7075C27.3857 28.8005 27.496 28.8742 27.6174 28.9244C27.7388 28.9747 27.8689 29.0006 28.0003 29.0006C28.1317 29.0006 28.2618 28.9747 28.3832 28.9244C28.5046 28.8742 28.6149 28.8005 28.7078 28.7075C28.8007 28.6146 28.8744 28.5043 28.9247 28.3829C28.975 28.2615 29.0008 28.1314 29.0008 28C29.0008 27.8686 28.975 27.7385 28.9247 27.6171C28.8744 27.4958 28.8007 27.3855 28.7078 27.2925ZM5.00029 14C5.00029 12.22 5.52813 10.48 6.51706 8.99991C7.50599 7.51987 8.9116 6.36631 10.5561 5.68513C12.2007 5.00394 14.0103 4.82571 15.7561 5.17297C17.5019 5.52024 19.1056 6.37741 20.3642 7.63608C21.6229 8.89475 22.4801 10.4984 22.8274 12.2442C23.1746 13.9901 22.9964 15.7997 22.3152 17.4442C21.634 19.0887 20.4805 20.4943 19.0004 21.4833C17.5204 22.4722 15.7803 23 14.0003 23C11.6141 22.9974 9.3265 22.0483 7.63925 20.3611C5.95199 18.6738 5.00293 16.3862 5.00029 14Z"
        fill="#B2B2B2"
      />
    </svg>
  );
};

export default IconSearch;
