import React from 'react';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';
const PartnerList = ({ fields, params, rendering }) => {
  return fields ? (
    <section
      className={`PartnerList relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems'],
          'Cards -Root Tab',
          'Cards -Edit Root Tab'
        )}
      >
        <div className="container">
          <div className="flex flex-col gap-10 p-6 bg-white lg:rounded-3xl rounded-2xl lg:p-10 lg:gap-14">
            <RichText
              data-aos="fade-up"
              tag="p"
              className="text-[28px]"
              field={fields.Description}
            />
            <div>
              {fields?.SelectedItems?.map((item) => (
                <>
                  {!!item?.fields?.CTALink?.value?.href ? (
                    <Link
                      field={item?.fields?.CTALink}
                      data-aos="fade-up"
                      key={item.id}
                      className="flex items-center gap-6 py-6 border-b last:border-none border-pastel-blue"
                    >
                      <div className="flex flex-col items-center justify-center w-20 h-20 overflow-hidden border rounded-full border-taziz-blue shrink-0">
                        <CustomImage
                          field={item.fields.Icon}
                          className="object-contain w-full h-full"
                        />
                      </div>
                      <RichText tag="p" className="typo-p-large" field={item.fields.Title} />
                    </Link>
                  ) : (
                    <div
                      data-aos="fade-up"
                      key={item.id}
                      className="flex items-center gap-6 py-6 border-b last:border-none border-pastel-blue"
                    >
                      <div className="flex flex-col items-center justify-center w-20 h-20 overflow-hidden border rounded-full border-taziz-blue shrink-0">
                        <CustomImage
                          field={item.fields.Icon}
                          className="object-contain w-full h-full"
                        />
                      </div>
                      <RichText tag="p" className="typo-p-large" field={item.fields.Title} />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'PartnerListComponent'} />
  );
};

export default PartnerList;
