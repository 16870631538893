import React from 'react';

const MultipleLineText = ({ props }) => {
  const { onChange } = props;

  const handleChange = (e) => {
    onChange(props.field.valueField.name, e.target.value, props.isValid, []);
  };
  return (
    <div className="flex flex-col w-full">
      <textarea
        rows={6}
        value={props.value}
        onChange={handleChange}
        id={props?.field?.model?.itemId}
        placeholder={props?.field?.model?.placeholderText}
        style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
        className="px-[14px] py-[10px] border-b-2 rounded-lg bg-hover-grey  text-grey-50 focus:outline-none placeholder:text-muted-blue-gray"
      />
      {props?.errors?.length > 0 && (
        <div className="mt-2 text-error text-base leading-[19.2px]">
          {props?.errors?.map((item) => item)}
        </div>
      )}
    </div>
  );
};

export default MultipleLineText;
