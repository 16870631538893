import React, { useEffect, useState } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import CustomImage from '../../../Common/CustomImage';
import IconCaret from '../../../Common/icons/IconCaret';

const Footer = ({ fields, params }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return fields ? (
    <>
      <BtnStandard
        extraClass={`fixed lg:bottom-10 bottom-4 rtl:left-4 rtl:right-[unset] ltr:right-4 lg:right-10 z-20 w-12 h-12 !p-2 rounded-full bg-taziz-blue duration-300 !w-[48px] ${
          isVisible ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
        type="PrimaryBlue"
        onClick={scrollToTop}
      >
        <IconCaret className="w-full h-full text-white -rotate-90" />
      </BtnStandard>
      <section
        className={`Footer relative ${
          params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
        } `}
      >
        <div className="container xl:py-[120px] py-10 lg:px-[145px] px-6 flex items-start justify-between xl:flex-row flex-col xl:gap-4 gap-10">
          <Link field={fields.Link}>
            <CustomImage field={fields.Logo} className="w-[100px]" />
          </Link>
          <div className="flex flex-col gap-10 xl:gap-6">
            <div className="flex flex-col gap-6 xl:flex-row xl:gap-14">
              {fields?.NavigationItems?.map((item) => (
                <BtnStandard
                  extraClass="!text-sm !font-bold"
                  key={item.id}
                  type="NoFillBlue"
                  href={item?.fields.Link?.value?.href}
                >
                  {item?.fields?.Link?.value?.text}
                </BtnStandard>
              ))}
            </div>
            <div className="flex flex-col gap-6 xl:flex-row xl:gap-12">
              {fields?.FooterNavigationItems?.map((item) => (
                <BtnStandard
                  extraClass="!text-sm"
                  key={item.id}
                  type="NoFillBlue"
                  href={item?.fields.Link?.value?.href}
                >
                  {item?.fields?.Link?.value?.text}
                </BtnStandard>
              ))}
            </div>
          </div>
          <div className="flex gap-6">
            {fields?.SocialNavigationItems?.map((item) => (
              <Link field={item.fields.Link} key={item.id} className="relative w-10 h-10 group">
                <CustomImage
                  field={item.fields.Icon}
                  className="absolute w-10 h-10 duration-300 group-hover:opacity-0"
                />
                <CustomImage
                  field={item.fields.HoverIcon}
                  className="absolute w-10 h-10 duration-300 opacity-0 group-hover:opacity-100"
                />
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  ) : (
    <PlaceholderComponent title={'FooterComponent'} />
  );
};

export default Footer;
