import React from 'react';

const IconArrowDown = ({ className }) => {
  return (
    <svg
      className={className ? className : 'w-6 h-6 text-taziz-blue'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.9997 9.16994C16.8123 8.98369 16.5589 8.87915 16.2947 8.87915C16.0305 8.87915 15.7771 8.98369 15.5897 9.16994L11.9997 12.7099L8.4597 9.16994C8.27234 8.98369 8.01889 8.87915 7.7547 8.87915C7.49052 8.87915 7.23707 8.98369 7.0497 9.16994C6.95598 9.26291 6.88158 9.37351 6.83081 9.49537C6.78004 9.61723 6.75391 9.74793 6.75391 9.87994C6.75391 10.012 6.78004 10.1427 6.83081 10.2645C6.88158 10.3864 6.95598 10.497 7.0497 10.5899L11.2897 14.8299C11.3827 14.9237 11.4933 14.9981 11.6151 15.0488C11.737 15.0996 11.8677 15.1257 11.9997 15.1257C12.1317 15.1257 12.2624 15.0996 12.3843 15.0488C12.5061 14.9981 12.6167 14.9237 12.7097 14.8299L16.9997 10.5899C17.0934 10.497 17.1678 10.3864 17.2186 10.2645C17.2694 10.1427 17.2955 10.012 17.2955 9.87994C17.2955 9.74793 17.2694 9.61723 17.2186 9.49537C17.1678 9.37351 17.0934 9.26291 16.9997 9.16994Z"
        fill="#001A70"
      />
    </svg>
  );
};

export default IconArrowDown;
