import React from 'react';
import Skeleton from '../Skeleton';

const SearchLoadingSkeleton = () => {
  return (
    <div className="flex flex-col gap-6">
      <Skeleton className={'h-5 w-[140px]'} />
      <Skeleton className={'h-[52px] md:h-[35px] w-full'} />
      <Skeleton className={'h-[20px]  w-[114px]'} />
    </div>
  );
};

export default SearchLoadingSkeleton;
