import React from 'react';
import { RichText, Image, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import { COLORS } from '../../../../utils/constants';
import CustomImage from '../../../Common/CustomImage';

const TextWithBackgroundColor = ({ fields, params }) => {
  return fields ? (
    <section
      className={`TextWithBackgroundColor relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div className="container">
        <div
          data-aos="fade-up"
          className="flex flex-col lg:gap-[60px] gap-8 lg:rounded-3xl rounded-2xl lg:p-10 p-6"
          style={{
            color: COLORS[params?.TextColor] || '#001A70',
            background: COLORS[params?.ShadowColor] || '#C9D3F5',
          }}
        >
          <div className="flex flex-col gap-6 lg:gap-10">
            <CustomImage field={fields?.Icon} className="object-cover w-10 h-10" />
            <Text field={fields?.PreTitle} />
            <Text tag="h3" field={fields?.Title} />
            <RichText tag="p" className="text-2xl" field={fields.Description} />
          </div>
          {fields?.CTALink?.value?.href && (
            <Link field={fields?.CTALink} className="w-fit">
              <BtnStandard type="PrimaryBlue" onClick={null}>
                {fields?.CTALink?.value?.text}
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </Link>
          )}
        </div>
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'TextWithBackgroundColorComponent'} />
  );
};

export default TextWithBackgroundColor;
