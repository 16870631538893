import React, { useEffect, useRef, useState } from 'react';
import IconPlus from '../../../Common/icons/IconPlus';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const AccordionItem = ({ question, answer, isOpen, onToggle }) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className="py-10 border-b-[1px] border-pastel-blue flex flex-col" data-aos="fade-up">
      <div
        className="flex flex-row gap-[40px] justify-between cursor-pointer items-center"
        onClick={onToggle}
      >
        <h4 className="text-taziz-blue">
          <Text field={question} />
        </h4>

        <IconPlus
          className={`${isOpen ? 'rotate-45' : ''
            } duration-200 ease-in-out h-6 w-6 md:h-8 md:w-8 text-taziz-green flex-none`}
        />
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-500 ease-in-out "
        style={{
          maxHeight: `${height}px`,
          opacity: isOpen ? 1 : 0,
        }}
      >
        <p className="typo-p-large md:max-w-[758px] text-balance md:mt-8 mt-6">
          <RichText field={answer} />
        </p>
      </div>
    </div>
  );
};

export default AccordionItem;
