import React from 'react'

const IconX = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8115 10.3155L21.8253 1H19.9263L12.9679 9.08852L7.41015 1H1L9.40433 13.2313L1 23H2.89914L10.2475 14.4583L16.1168 23H22.5269L13.811 10.3155H13.8115ZM11.2103 13.339L10.3588 12.1211L3.58343 2.42965H6.5004L11.9682 10.2509L12.8197 11.4689L19.9272 21.6354H17.0102L11.2103 13.3395V13.339Z" fill="currentColor" />
        </svg>
    )
}

export default IconX