import React from 'react';
import NewsItem from './NewsItem';
import VideoItem from './VideoItem';
import VideoHighligh from './VideoHighligh';
import NewsHighlight from './NewsHighlight';

const Results = ({ sort, results, phrases }) => {
  const renderItem = (el, isFirst) => {
    if (el.item.template.name === 'GalleryVideoItem') {
      return isFirst ? (
        <VideoHighligh item={el} phrases={phrases} />
      ) : (
        <>
          {sort === 'GALLERY' ? (
            <VideoItem item={el} phrases={phrases} />
          ) : (
            <NewsItem item={el} hasVideo={el.item.template.name !== 'News'} phrases={phrases} />
          )}
        </>
      );
    } else if (el.item.template.name === 'News') {
      return isFirst ? (
        <>
          {sort === 'GALLERY' ? (
            <VideoHighligh item={el} phrases={phrases} />
          ) : (
            <NewsHighlight item={el} phrases={phrases} />
          )}
        </>
      ) : (
        <>
          {sort === 'GALLERY' ? (
            <VideoItem item={el} phrases={phrases} />
          ) : (
            <NewsItem item={el} hasVideo={el.item.template.name !== 'News'} phrases={phrases} />
          )}
        </>
      );
    }
    return null; // Fallback in case template doesn't match
  };
  return (
    <>
      {sort === 'GALLERY' ? (
        <div className="flex flex-col flex-wrap  md:flex-row justify-center md:justify-start gap-[24px] w-full">
          {results.map((el, i) => renderItem(el, i === 0))}
        </div>
      ) : sort === 'ARTICLES' ? (
        <div className="flex flex-col md:flex-row justify-center md:justify-start  gap-[24px] lg:gap-[40px] w-full flex-wrap">
          {results.map((el, i) => renderItem(el, i === 0))}
        </div>
      ) : (
        <div className="flex flex-col flex-wrap  md:flex-row justify-center md:justify-start gap-[24px]  lg:gap-[40px]  w-full">
          {results.map((el, i) => renderItem(el, i === 0))}
        </div>
      )}
    </>
  );
};

export default Results;
