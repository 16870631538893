import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import {EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';
const Insights = ({ fields, params ,rendering }) => {
  const gridClassNames = {
    2: 'grid grid-cols-1 lg:grid-cols-2',
    3: 'grid grid-cols-1 lg:grid-cols-3',
    4: 'grid grid-cols-1 lg:grid-cols-4',
  };

  return fields ? (
    <section
      className={`Insights relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
       <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['SelectedItems'],
          'Cards -Root Tab',
          'Cards -Edit Root Tab'
        )}
      >
      <div className="container">
        <div
          className={`lg:w-[calc(100%+56px)] lg:-ml-7 ${
            gridClassNames[fields?.SelectedItems?.length]
          }`}
        >
          {fields?.SelectedItems.map((item, index) => (
            <div
              data-aos="fade-up"
              key={item.id}
              className="border-0 border-taziz-blue border-b py-6 last:border-b-0 last:pb-0 lg:border-b-0 lg:border-e last:lg:border-e-0 lg:!pt-0 lg:!pb-6 lg:px-7"
            >
              <div
                className={`${
                  index === 0
                    ? 'lg:float-start'
                    : index === fields?.SelectedItems?.length - 1
                    ? 'lg:float-end'
                    : 'lg:mx-auto'
                } w-fit h-full flex flex-col justify-between gap-4 lg:gap-10`}
              >
                <Text tag="p" className="uppercase" field={item.fields?.PreTitle} />
                <div className="flex">
                  <Text
                    tag="div"
                    className="text-[80px] leading-[110%] font-normal"
                    field={item.fields?.Title}
                  />
                  <RichText
                    tag="div"
                    className="text-[24px] leading-[110%] font-normal"
                    field={item.fields?.InsightsSubTitle}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'InsightsComponent'} />
  );
};

export default Insights;
