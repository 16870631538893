import React, { useState } from 'react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import AccordionItem from './AccordionItem';
import { Text, EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';


const FAQ = ({ fields, params, rendering }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return fields ? (
    <section
      className={`${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'} ${params?.RemovePaddingFrom === 'top' ? '!pt-0' : params?.RemovePaddingFrom === 'bottom' ? '!pb-0' : params?.RemovePaddingFrom === 'both' ? '!py-0' : ''} py-10 FAQ  md:py-[120px] container`}
    >
       <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems'],
          'FAQs -Root Tab',
          'FAQs -Edit Root Tab'
        )}
      >
      <h2 className="mb-[40px] md:mb-[80px] text-taziz-blue" data-aos="fade-up">
        <Text field={fields.Title} />
      </h2>
      {fields?.SelectedItems?.map((faq, i) => (
            <EditFrame
            {...getEditFrameProps(
              faq.id || '',
              ['Title', 'Description'],
              'FAQs - Card-' + i,
              'FAQs-Edit Card-' + i
            )}
            >
        <AccordionItem
          key={`faq_item_${i}`}
          question={faq?.fields?.Title}
          answer={faq?.fields?.Description}
          isOpen={activeIndex === i}
          onToggle={() => handleToggle(i)}
        />
        </EditFrame>
      ))}
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'FAQComponent'} />
  );
};

export default FAQ;
