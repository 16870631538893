import React from 'react';
import useFontAlignmentFix from '../../../hooks/useFontAlignmentFix';

const BtnStandard = ({
  extraClass,
  type = 'PrimaryWhite',
  size = 'normal',
  children,
  href,
  onClick,
}) => {
  const { needsFontAlignmentFix, device } = useFontAlignmentFix();

  const className = {
    PrimaryWhite:
      'bg-white text-taziz-blue hover:bg-taziz-green active:text-taziz-blue active:bg-pressed-grey',
    PrimaryBlue:
      'bg-taziz-blue text-white hover:bg-taziz-green hover:text-taziz-blue active:text-taziz-blue active:bg-taziz-blue-active',
    SecondaryWhite:
      'border-[2px] border-white text-white hover:border-taziz-green hover:text-taziz-green active:text-white active:border-white active:bg-pressed-grey',
    SecondaryBlue:
      'border-[2px] border-taziz-blue text-taziz-blue hover:border-taziz-green hover:text-taziz-green active:text-taziz-blue active:border-taziz-blue active:bg-taziz-blue-active',
    NoFillWhite: '!py-0 !px-0 text-white hover:text-taziz-green active:text-pressed-grey',
    NoFillBlue: '!py-0 !px-0 text-taziz-blue hover:text-taziz-green active:text-taziz-blue-active',
  };

  return href ? (
    <a
      href={href}
      className={`w-fit rounded-full px-4 py-2 flex ${
        needsFontAlignmentFix && type !== 'NoFillWhite'
          ? device === 'Windows'
            ? 'items-end'
            : 'items-start'
          : 'items-center'
      } gap-[10px] ${size === 'small' ? 'typo-p-small-bold' : 'typo-p'}  ${
        className[type] ? className[type] : className['PrimaryWhite']
      } transition-all duration-300 ${extraClass}`}
    >
      {children}
    </a>
  ) : (
    <button
      onClick={onClick}
      className={`w-fit rounded-full px-4 py-2 flex ${
        needsFontAlignmentFix && type !== 'NoFillWhite'
          ? device === 'Windows'
            ? 'items-end'
            : 'items-start'
          : 'items-center'
      } gap-[10px] ${size === 'small' ? 'typo-p-small-bold' : 'typo-p'} ${
        className[type] ? className[type] : className['PrimaryWhite']
      } transition-all duration-300 ${extraClass}`}
    >
      {children}
    </button>
  );
};

export default BtnStandard;
