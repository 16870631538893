import React, { useState } from 'react';
import IconArrowCircle from '../../../../Common/icons/IconArrowCircle';
import BtnStandard from '../../../../Common/buttons/BtnStandard';
import VideoPopup from '../../../../Common/popups/VideoPopup';
import { createPortal } from 'react-dom';

const SearchItem = ({ item, phrases }) => {
  const [popupOpened, set_popupOpened] = useState(false);
  const isVideo = item.item.__typename === 'GalleryVideoItem';

  const displayTitle = (() => {
    if (isVideo) {
      return item?.item?.videoTitle?.value || item?.item?.name;
    }
    if (item?.item?.__typename === 'News' || item.item.__typename === 'ContentPage') {
      return item?.item?.headline?.value || item?.item?.name;
    }
    return item?.item?.name;
  })();

  return (
    <div className="flex flex-col gap-6" data-aos="fade-up">
      <p className="text-taziz-blue">{item?.item?.date?.value}</p>
      <h4 className="text-dark-teal">{displayTitle}</h4>
      <BtnStandard
        type="NoFillBlue"
        href={isVideo ? void 0 : item?.item?.url}
        onClick={() => (isVideo ? set_popupOpened(true) : null)}
      >
        <p>{phrases?.['read-more']}</p>
        <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
      </BtnStandard>
      {popupOpened && isVideo && (
        <>
          {createPortal(
            <VideoPopup
              videoLink={item?.item?.videoLink?.href}
              closePopup={() => set_popupOpened(false)}
            />,
            document.body
          )}
        </>
      )}
    </div>
  );
};

export default SearchItem;
