import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MEDIA_PAGE_SIZE, SEARCH_PAGE_SIZE } from '../../../../utils/constants';
import SearchControllers from './SearchComponents/SearchControllers';
import NoResultsFound from './SearchComponents/NoResultsFound';
import SearchItem from './SearchComponents/SearchItem';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import { GetSearchResult } from './SearchComponents/getSearchQuery';
import Skeleton from '../../../Common/Skeleton';
import SearchLoadingSkeleton from '../../../Common/LoadingSkeletons/SearchLoadingSkeleton';
import SearchTitle from './SearchComponents/SearchTitle';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const SearchResults = ({ fields, params }) => {
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const [query, setQuery] = useState(q ? q : '');
  const [inputText, set_inputText] = useState(q ? q : '');
  const [sort, setSort] = useState('Newest');
  const [isFirstLoading, set_isFirstLoading] = useState(false);
  const [results, set_results] = useState([]);
  const [totalCount, set_totalCount] = useState(0);
  const [pageNum, set_pageNum] = useState(0);
  const [hasNextPage, set_hasNextPage] = useState();
  const [fetching, set_fetching] = useState(false);
  const [phrases, set_phrases] = useState(null);
  const [noResults, set_noResults] = useState(false);

  useEffect(() => {
    if (window?.phrases) set_phrases({ ...window.phrases });
    else set_phrases(null);
  }, []);
  const { loading, error, data, refetch } = useQuery(GetSearchResult, {
    variables: {
      language: language,
      pageSize: SEARCH_PAGE_SIZE,
      skip: "0",
      keyword: query,
      sortDesc: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadMore = () => {
    refetchResults(pageNum + 1);
  };

  const refetchResults = (pageNum) => {
    set_fetching(true);
    if (!pageNum) {
      set_results([]);
      set_hasNextPage(false);
    }
    set_pageNum(pageNum);
    refetch({
      skip: (MEDIA_PAGE_SIZE * pageNum).toString(),
      keyword: query,
      sortDesc: sort === 'Newest' ? true : false,
    });
  };

  useEffect(() => {
    if (!isFirstLoading) {
      refetchResults(0);
    }
  }, [sort, q, query]);

  useEffect(() => {
    setQuery(q);
    set_inputText(q);
  }, [q]);

  useEffect(() => {
    if (!loading) {
      set_totalCount(data?.extendedsearch?.results?.totalCount);
      set_hasNextPage(data?.extendedsearch?.results?.pageInfo?.hasNextPage);
      const newResults = data?.extendedsearch?.results?.items || [];
      if (pageNum) {
        set_results([...results, ...newResults]);
      } else {
        set_results(newResults);
      }
      if (data?.extendedsearch?.results?.totalCount === 0) {
        set_noResults(true);
      } else {
        set_noResults(false);
      }
      set_fetching(false);
      if (isFirstLoading) {
        set_isFirstLoading(false);
      }
    }
  }, [loading]);

  return (
    <section
      className={`${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'} ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      } py-10 SearchResults md:pt-20 md:pb-[120px] `}
    >
      <div className="container">
        <div className="flex flex-col gap-10 md:gap-20">
          {/* Render search  controls for query and sorting. */}
          <SearchControllers
            phrases={phrases}
            set_inputText={set_inputText}
            sort={sort}
            setQuery={setQuery}
            setSort={setSort}
            inputText={inputText}
          />

          {/* Render search Title */}
          {fetching && !pageNum ? (
            <Skeleton className={'h-7 w-full md:w-1/2'} />
          ) : (
            <>
              {totalCount > 0 && (
                <SearchTitle totalCount={totalCount} query={query} phrases={phrases} />
              )}
            </>
          )}

          {!totalCount && !fetching && !loading && noResults ? (
            // Render not found component
            <NoResultsFound phrases={phrases} />
          ) : (
            <>
              {/* displaying the results */}
              {results.map((item, i) => (
                <SearchItem key={`search_${i}`} item={item} phrases={phrases} />
              ))}
            </>
          )}
          {fetching ? (
            /* Loading skeleton when fetching */
            Array(SEARCH_PAGE_SIZE)
              .fill(0)
              .map((_, i) => <SearchLoadingSkeleton key={`search_loading_${i}`} />)
          ) : hasNextPage ? (
            /* Load more CTA */
            <div className="mx-auto w-fit" data-aos="fade-up">
              <BtnStandard type="PrimaryBlue" onClick={() => loadMore()}>
                <p>{`${phrases?.['load-more']}`}</p>
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default SearchResults;
