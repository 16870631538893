import React from 'react';
import useFontAlignmentFix from '../../../hooks/useFontAlignmentFix';

const IconArrowCircle = ({ className }) => {
  const { needsFontAlignmentFix } = useFontAlignmentFix();
  return (
    <svg
      className={`${className ? className : 'w-6 h-6 text-taziz-blue'} ${
        needsFontAlignmentFix ? 'mb-[2px]' : ''
      }`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM11.4697 8.53033C11.1768 8.23744 11.1768 7.76256 11.4697 7.46967C11.7626 7.17678 12.2374 7.17678 12.5303 7.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303C11.1768 16.2374 11.1768 15.7626 11.4697 15.4697L14.1893 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H14.1893L11.4697 8.53033Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconArrowCircle;
