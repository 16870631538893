import React, { useEffect, useState } from 'react';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import { useQuery } from '@apollo/client';
import { MEDIA_PAGE_SIZE, SEARCH_PAGE_SIZE } from '../../../../utils/constants';
import LoadingEffect from './LoadingEffect';
import Results from './Results';
import Tabs from './Tabs';
import { GetMediaListing } from './getMediaListing';
import NoResultsFound from '../SearchResults/SearchComponents/NoResultsFound';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const MediaCenter = ({ fields, params }) => {
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;
  const [sort, setSort] = useState('ALL');
  const [fieldsEqual, setFieldsEqual] = useState([
    {
      name: '_template',
      value: 'in:89c70bc7887c457d894425d6a9a4c8b4,52d930bed0ad4b3c98906f69dfd284ce',
    },
  ]);
  const [results, set_results] = useState(fields?.data?.extendedsearch?.results?.items || []);
  const [pageNum, set_pageNum] = useState(0);
  const [hasNextPage, set_hasNextPage] = useState(
    fields?.data?.extendedsearch?.results?.pageInfo?.hasNextPage
  );
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [fetching, set_fetching] = useState(false);
  const [phrases, set_phrases] = useState(null);

  useEffect(() => {
    if (window?.phrases) set_phrases({ ...window.phrases });
    else set_phrases(null);
  }, []);
  const { loading, error, data, refetch } = useQuery(GetMediaListing, {
    variables: {
      language: language,
      skip: '0',
      fieldsEqual: fieldsEqual,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const loadMore = () => {
    refetchResults(pageNum + 1);
  };

  const refetchResults = (pageNum) => {
    set_fetching(true);
    if (!pageNum) {
      set_results([]);
      set_hasNextPage(false);
    }
    set_pageNum(pageNum);
    refetch({
      skip: (MEDIA_PAGE_SIZE * pageNum).toString(),
    });
  };

  useEffect(() => {
    if (!isFirstLoad) {
      refetchResults(0);
    }
  }, [sort]);

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        set_isFirstLoad(false);
      } else {
        set_hasNextPage(data?.extendedsearch?.results?.pageInfo?.hasNextPage);
        const newResults = data?.extendedsearch?.results?.items || [];
        if (pageNum) {
          set_results([...results, ...newResults]);
        } else {
          set_results(newResults);
        }
        set_fetching(false);
        if (isFirstLoad) {
          set_isFirstLoad(false);
        }
      }
    }
  }, [loading]);

  return (
    <section
      className={`${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-neutral-grey'} ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : ''
      } py-10 MediaCenter md:pt-20 md:pb-[80px] `}
    >
      <div className="container">
        <div className="flex flex-col gap-10 md:gap-10">
          {/* Render search  controls for query and sorting. */}
          <Tabs sort={sort} setSort={setSort} setFieldsEqual={setFieldsEqual} phrases={phrases} />

          {!results.length && !fetching && !loading ? (
            // Render not found component
            <div className="md:pt-20">
              <NoResultsFound phrases={phrases} />
            </div>
          ) : (
            //  displaying the results
            <Results results={results} sort={sort} phrases={phrases} />
          )}
          {fetching ? (
            <LoadingEffect sort={sort} firstLoad={results.length < 4} />
          ) : hasNextPage ? (
            /* Load more CTA */
            <div className="mx-auto w-fit" data-aos="fade-up">
              <BtnStandard type="PrimaryBlue" onClick={() => loadMore()}>
                <p>{`${phrases?.['load-more']}`}</p>
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default MediaCenter;
