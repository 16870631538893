import React, { useState } from 'react';
import IconPlay from '../../../Common/icons/IconPlay';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import VideoPopup from '../../../Common/popups/VideoPopup';
import CustomImage from '../../../Common/CustomImage';

const VideoItem = ({ item }) => {
  const [popupOpened, set_popupOpened] = useState(false);
  return (
    <div className="md:w-[calc(50%-48px)] lg:w-1/3 lg:max-w-[360px] w-full aspect-[16/9] rounded-3xl overflow-hidden group relative h-[212px] grid place-items-center">
      <div className="w-full h-full overflow-hidden">
        <CustomImage
          field={item?.item?.thumbnailImage}
          className="object-cover w-full h-full duration-300 group-hover:scale-110"
        />
        <div
          onClick={
            item?.item?.videoLink?.href
              ? void 0
              : () => {
                  set_popupOpened(true);
                }
          }
          className={`${item?.item?.videoLink?.href ? '' : 'cursor-pointer'} absolute inset-0 z-10`}
          style={{
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40)',
          }}
        ></div>
        {item?.item?.videoLink?.href && (
          <BtnStandard type="NoFillWhite" onClick={() => set_popupOpened(true)}>
            <IconPlay className="absolute z-[20] text-white duration-200 hover:text-taziz-green h-14 w-14 left-[50%] top-[50%] cursor-pointer translate-x-[-50%] translate-y-[-50%]" />
          </BtnStandard>
        )}
      </div>
      {popupOpened && (
        <VideoPopup
          videoTitle={item?.item?.videoTitle}
          imgField={item?.item?.thumbnailImage}
          videoLink={item.item.videoLink?.href}
          closePopup={() => set_popupOpened(false)}
        />
      )}
    </div>
  );
};

export default VideoItem;
