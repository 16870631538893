import { useEffect, useState } from 'react';

const useExperienceEditor = () => {
  const [inExperienceEditor, set_inExperienceEditor] = useState(null);
  useEffect(() => {
    let isPageEditor = !!(
      typeof Sitecore !== 'undefined' &&
      typeof Sitecore.PageModes !== 'undefined' &&
      typeof Sitecore.PageModes.PageEditor !== 'undefined'
    );
    console.log(`---------- isPageEditor() : ${isPageEditor} -------------------`);
    set_inExperienceEditor(isPageEditor);
  }, []);

  return inExperienceEditor;
};

export default useExperienceEditor;
