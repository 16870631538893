import React, { useRef } from 'react';
import { RichText, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import IconCaret from '../../../Common/icons/IconCaret';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const HeroBanner = ({ fields, params, rendering }) => {
  const firstSectionRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToNextSection = () => {
    const firstSectionHeight = firstSectionRef.current.clientHeight;
    window.scrollTo({
      top: firstSectionHeight,
      behavior: 'smooth',
    });
  };

  return fields ? (
    <section
      ref={firstSectionRef}
      className={`HeroBanner relative justify-start min-h-screen pt-[123.5px] pb-0 md:py-[180px] flex flex-col md:justify-center ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'Description', 'Icon', 'Image', 'Link', 'MobileImage'],
          'HeroBanner -Root Tab',
          'HeroBanner -Edit Root Tab'
        )}
      >
        {fields.VideoLink?.value?.href ? (
          <video
            src={fields.VideoLink?.value?.href}
            playsInline
            autoPlay
            muted
            loop
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
          />
        ) : fields?.Image?.value?.src ? (
          <CustomImage
            isLCP={true}
            field={
              isMobile
                ? fields.MobileImage?.value?.src
                  ? fields.MobileImage
                  : fields.Image
                : fields.Image
            }
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
          />
        ) : null}
        <div className="absolute top-0 left-0 z-10 w-full h-full bg-taziz-blue/40"></div>
        <BtnStandard
          extraClass="absolute left-0 right-0 z-20 w-8 h-8 !p-2 mx-auto rounded-full bg-taziz-blue bottom-10 !w-[32px]"
          type="PrimaryBlue"
          onClick={scrollToNextSection}
        >
          <IconCaret className="w-full h-full text-white rotate-90" />
        </BtnStandard>
        <div className="container relative z-20 flex flex-col gap-8 lg:gap-10">
          <CustomImage isLCP={true} field={fields.Icon} className="w-[100px]" />

          <div data-aos="fade-up" className="max-w-[898px] w-full flex flex-col lg:gap-6 gap-4">
            <Text tag="h1" className="text-white uppercase font-[500]" field={fields.Title} />
            <RichText className="text-white typo-p-large " field={fields.Description} />
          </div>
          {fields?.Link?.value?.href && (
            <div data-aos="fade-up" className="w-fit">
              <Link field={fields?.Link}>
                <BtnStandard type="PrimaryWhite">
                  {fields?.Link?.value?.text}
                  <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
                </BtnStandard>
              </Link>
            </div>
          )}
        </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'HeroBannerComponent'} />
  );
};

export default HeroBanner;
