import React from 'react'

const IconLocationPin = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.13401 3 5 6.13401 5 10C5 11.8921 5.85317 13.678 7.29228 15.5467C8.50741 17.1245 10.0627 18.6673 11.7323 20.3233C11.8212 20.4115 11.9105 20.5 12 20.5889C12.0895 20.5 12.1788 20.4115 12.2677 20.3233C13.9373 18.6673 15.4926 17.1245 16.7077 15.5467C18.1468 13.678 19 11.8921 19 10C19 6.13401 15.866 3 12 3ZM3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10C21 12.5262 19.8532 14.7402 18.2923 16.767C16.988 18.4607 15.3185 20.1156 13.6508 21.7689C13.3354 22.0816 13.02 22.3943 12.7071 22.7071C12.3166 23.0976 11.6834 23.0976 11.2929 22.7071C10.98 22.3943 10.6646 22.0816 10.3492 21.7689C8.68147 20.1156 7.01205 18.4607 5.70772 16.767C4.14683 14.7402 3 12.5262 3 10ZM12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8ZM8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10Z" fill="currentColor" />
        </svg>
    )
}

export default IconLocationPin