import React from 'react';

const SearchTitle = ({ totalCount, query, phrases }) => {
  return (
    <h4 data-aos="fade-up" className="uppercase" style={{ wordBreak: 'break-word' }}>
      <span className="text-taziz-green">
        {totalCount}
        {` ${phrases?.['results']} `}
      </span>
      {query ? `${phrases?.['found-for']} ` : ''}
      {query && <span className="text-taziz-green">{`“${query}”`}</span>}
    </h4>
  );
};

export default SearchTitle;
