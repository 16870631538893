import React from 'react';
import BtnTab from '../../../Common/buttons/BtnTab';
import useFontAlignmentFix from '../../../../hooks/useFontAlignmentFix';

const Tabs = ({ setSort, sort, setFieldsEqual, phrases }) => {
  const { device, language } = useFontAlignmentFix();
  const handleTabChange = (tab) => {
    setSort(tab.item);
    setFieldsEqual(tab.fieldsEqual);
  };
  const TABS_DATA = [
    {
      item: 'ALL',
      fieldsEqual: [
        {
          name: '_template',
          value: 'in:89c70bc7887c457d894425d6a9a4c8b4,52d930bed0ad4b3c98906f69dfd284ce',
        },
      ],
      dictionary: phrases?.['all'],
    },
    {
      item: 'ARTICLES',
      fieldsEqual: [
        {
          name: '_template',
          value: 'in:89c70bc7887c457d894425d6a9a4c8b4',
        },
      ],
      dictionary: phrases?.['Articles'],
    },
    {
      item: 'GALLERY',
      fieldsEqual: [
        {
          name: '_template',
          value: 'in:52d930bed0ad4b3c98906f69dfd284ce',
        },
      ],
      dictionary: phrases?.['gallery'],
    },
  ];

  return (
    <div className="flex flex-row gap-4">
      {TABS_DATA.map((el, i) => (
        <BtnTab
          key={`${i}_tabs`}
          type={'Blue'}
          active={el.item === sort}
          onClick={() => {
            handleTabChange(el);
          }}
        >
          <span className={`${device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''}`}>
            {el.dictionary}
          </span>
        </BtnTab>
      ))}
    </div>
  );
};

export default Tabs;
