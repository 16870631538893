import React from 'react';
import { RichText, Image, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import CustomImage from '../../../Common/CustomImage';

const ShadowImageAndContentBlock = ({ fields, params }) => {
  return fields ? (
    <section
      className={`ShadowImageAndContentBlock relative py-10 lg:py-[120px] flex flex-col gap-10 lg:gap-20 ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div
        data-aos="fade-up"
        className={`container flex items-center gap-10 xl:gap-20 ${
          params?.ImageAlignment === 'right'
            ? 'xl:flex-row-reverse flex-col'
            : 'xl:flex-row flex-col'
        }`}
      >
        <div className="relative w-full pb-4 xl:w-1/2 pe-4 xl:pb-10 xl:pe-10">
          <CustomImage
            className="relative z-10 w-full h-full xl:h-[480px] xl:rounded-3xl rounded-2xl xl:object-cover xl:object-center"
            field={fields.Image}
          />
          <div
            className={`z-0 absolute w-full h-full ps-4 pt-4 xl:ps-10 xl:pt-10 right-0 bottom-0`}
          >
            <div
              className={`w-full h-full xl:rounded-3xl rounded-2xl ${
                params?.BackgroundTheme === 'dark' ? 'bg-dark-green' : 'bg-taziz-green'
              }`}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-10 xl:w-1/2">
          <div data-aos="fade-up" className="w-[151px] h-1 rouned-full bg-taziz-blue" />
          <Text data-aos="fade-up" tag="h2" className="uppercase" field={fields.Title} />
          <RichText data-aos="fade-up" className="typo-p" field={fields.Description} />
          {fields.SelectedItems?.length > 0 && (
            <div
              data-aos="fade-up"
              className="grid grid-cols-1 gap-10 sm:gap-0 xl:gap-10 2xl:gap-0 sm:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2"
            >
              {fields.SelectedItems?.slice(0, 2)?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-4 border-0 border-taziz-blue first:!ps-0 first:sm:!pe-10 first:xl:!pe-0 first:2xl:!pe-10 first:sm:border-e-[1px] first:xl:border-e-0 first:2xl:border-e-[1px] last:!border-0 last:sm:ps-10 last:xl:ps-0 last:2xl:ps-10"
                >
                  <div className="flex items-center gap-2">
                    <CustomImage className="w-6 h-6" field={item.fields.Icon} />
                    <Text
                      tag="p"
                      className="typo-p-large lg:text-[22px]"
                      field={item.fields.Title}
                    />
                  </div>
                  <RichText className="typo-p" field={item.fields.Description} />
                </div>
              ))}
            </div>
          )}
          {fields.SelectedItems?.[2] && (
            <div data-aos="fade-up" className="flex flex-col gap-4 ">
              <div className="flex items-center gap-2">
                <CustomImage className="w-6 h-6" field={fields.SelectedItems?.[2]?.fields?.Icon} />
                <Text
                  tag="p"
                  className="typo-p-large"
                  field={fields.SelectedItems?.[2]?.fields?.Title}
                />
              </div>
              <RichText className="typo-p" field={fields.SelectedItems?.[2]?.fields?.Description} />
              {fields.SelectedItems?.[2]?.fields?.SelectedItems?.length > 0 && (
                <div className="flex flex-wrap gap-4">
                  {fields.SelectedItems?.[2]?.fields?.SelectedItems?.map((item, index) =>
                    item.fields?.CTALink?.value?.href ? (
                      <Link
                        field={item.fields?.CTALink}
                        key={index}
                        className="flex items-center justify-center w-20 h-20 p-4 border rounded-full border-taziz-blue"
                      >
                        <CustomImage className="" field={item.fields.Icon} />
                      </Link>
                    ) : (
                      <div
                        key={index}
                        className="flex items-center justify-center w-20 h-20 p-4 border rounded-full border-taziz-blue"
                      >
                        <CustomImage className="" field={item.fields.Icon} />
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'ShadowImageAndContentBlockComponent'} />
  );
};

export default ShadowImageAndContentBlock;
