import { gql } from '@apollo/client';

export const GetMediaListing = gql`
  query (
    $fieldsEqual: [ItemSearchFieldQuery]
    $skip: String
    $language: String
  ) {
    Component: item(path: "{43B2AC5E-61B9-4022-B393-FCD850725725}", language: $language) {
      ... on GQLSettings {
        title {
          value
        }
        link {
          ... on LinkField {
            anchor
            className
            href: url
            text
            linkType
            id
          }
        }
      }
    }
    extendedsearch(
      first: 4
      after: $skip
      rootItem: "{01C292A1-FC79-450F-BE64-25F894345546}"
      language: $language
      sortBy: "Date"
      sortDesc: true
      index: "taziz_{database}_index"
      fieldsEqual: $fieldsEqual
    ) {
      results {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        items {
          item {
            ... on News {
              headline {
                value
              }
              teaser {
                value
              }
              date {
                ... on DateField {
                  value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ssZ")
                }
              }
              featuredImage {
                ... on ImageField {
                  src
                  alt
                  width
                  height
                }
              }
              template {
                name
              }
            }
            ... on GalleryVideoItem {
              date {
                ... on DateField {
                  value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ssZ")
                }
              }
              videoTitle {
                value
              }
              videoLink {
                ... on LinkField {
                  anchor
                  className
                  href: url
                  text
                  linkType
                  id
                }
              }
              thumbnailImage {
                ... on ImageField {
                  src
                  alt
                  width
                  height
                }
              }
              template {
                name
              }
            }
            url
          }
        }
      }
    }
  }
`;
