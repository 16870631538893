import React from 'react';
import { Text, Image, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import CustomImage from '../../../Common/CustomImage';

const TitleAndDescription = ({ fields, params }) => {
  return fields ? (
    <section
      className={`TitleAndDescription relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      }  ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div className="container flex flex-col gap-10 lg:gap-20">
        <div data-aos="fade-up" className="flex items-center gap-2">
          <CustomImage field={fields.Icon} className="w-10 h-10" />
          <div className="w-6 lg:w-10 h-[2px] bg-taziz-blue" />
          <p className="w-[calc(100%-80px)] lg:w-[calc(100%-104px)] uppercase">
            <Text field={fields.PreTitle} />
          </p>
        </div>
        <div className="flex flex-col gap-4 lg:gap-10">
          <h2 className="uppercase">
            <Text data-aos="fade-up" field={fields.Title} />
          </h2>
          <RichText data-aos="fade-up" className="typo-p-large" field={fields.Description} />
          {fields.CTALink?.value?.href && (
            <div data-aos="fade-up" className="w-fit">
              <Link field={fields.CTALink}>
                <BtnStandard type="NoFillBlue">
                  {fields.CTALink?.value?.text}
                  <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
                </BtnStandard>
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'TitleAndDescriptionComponent'} />
  );
};

export default TitleAndDescription;
