import React, { useEffect, useRef, useState } from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { SwiperSlide, Swiper } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import { Autoplay, EffectFade } from 'swiper/modules';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnTab from '../../../Common/buttons/BtnTab';
import Badge from '../../../Common/Badge';
import useExperienceEditor from '../../../../hooks/useExperienceEditor';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';
import useFontAlignmentFix from '../../../../hooks/useFontAlignmentFix';
const ProgressBar = ({ index }) => {
  const [active, set_active] = useState(false);

  useEffect(() => {
    set_active(false);
  }, [index]);

  useEffect(() => {
    if (!active) {
      setTimeout(() => {
        set_active(true);
      }, 500);
    }
  }, [active]);

  return (
    <div className={`ProgressBar h-full rounded-full bg-white ${active ? 'active' : 'inactive'}`} />
  );
};

const TabbedCarousel = ({ fields, params, isRtl, rendering }) => {
  const { device, language } = useFontAlignmentFix();
  const mobileTabSwiper = useRef('mobileTabSwiper');
  const bgSwiperRef = useRef('bgSwiperRef');
  const mainSwiperRef = useRef('mainSwiperRef');
  const tabRef = useRef('tabRef');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const duration = 10000;
  const inExperienceEditor = useExperienceEditor();

  const [activeTab, set_activeTab] = useState(0);

  useEffect(() => {
    bgSwiperRef.current.swiper.slideTo(activeTab);
    mainSwiperRef.current.swiper.slideTo(activeTab);
    mobileTabSwiper.current?.swiper?.slideTo(activeTab);
  }, [activeTab]);

  return fields ? (
    <section
      className={`TabbedCarousel relative w-full h-fit bg-dark-green py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      }  ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Icon', 'PreTitle', 'SelectedItems'],
          'TabbedCarousel -Root Tab',
          'TabbedCarousel -Edit Root Tab'
        )}
      >
        <div className="absolute top-0 left-0 w-full h-full">
          <Swiper
            ref={bgSwiperRef}
            className="w-full h-full"
            effect={'fade'}
            navigation={false}
            slidesPerView={1}
            modules={[EffectFade]}
            speed={1000}
          >
            {fields.SelectedItems.map((item, index) => (
              <SwiperSlide key={index} className="w-full h-full">
                <CustomImage
                  field={
                    isMobile
                      ? item.fields.MobileImage?.value?.src
                        ? item.fields.MobileImage
                        : item.fields.Image
                      : item.fields.Image
                  }
                  className="object-cover object-center w-full h-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="absolute top-0 left-0 z-10 w-full h-full bg-black/60" />
        </div>
        <div className="container relative z-10 flex flex-col gap-10 text-white lg:gap-20 h-fit">
          <div data-aos="fade-up" className="flex items-center gap-2">
            <CustomImage field={fields.Icon} className="w-10 h-10" />
            <div className="w-6 lg:w-10 h-[2px] bg-white" />
            <Text
              tag="p"
              className="w-[calc(100%-80px)] lg:w-[calc(100%-104px)] uppercase"
              field={fields.PreTitle}
            />
          </div>
          <div className="flex flex-col gap-10 lg:flex-row lg:gap-6 h-fit">
            <div data-aos="fade-up" className="w-full h-1 rounded-full lg:hidden bg-taziz-blue">
              <ProgressBar index={activeTab} />
            </div>
            <div data-aos="fade-up" className="anti-container lg:hidden">
              <Swiper
                ref={mobileTabSwiper}
                className="w-full"
                spaceBetween={10}
                navigation={false}
                slidesPerView={'auto'}
                dir={isRtl ? 'rtl' : 'ltr'}
                speed={1000}
              >
                {fields.SelectedItems.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className="!w-fit first:ms-4 first:sm:ms-6 first:md:ms-12 last:me-4 last:sm:me-6 last:md:me-12"
                  >
                    <BtnTab
                      type="White"
                      active={index === activeTab}
                      onClick={() => set_activeTab(index)}
                    >
                      <Text
                        tag="div"
                        className={`${
                          device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''
                        }`}
                        field={item.fields.PreTitle}
                      />
                    </BtnTab>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              data-aos="fade-up"
              ref={tabRef}
              className="hidden w-[207px] lg:flex gap-[10px] lg:flex-col lg:gap-4"
            >
              {fields.SelectedItems.map((item, index) => (
                <BtnTab
                  key={item.id}
                  type="White"
                  active={index === activeTab}
                  onClick={() => set_activeTab(index)}
                >
                  <EditFrame
                    {...getEditFrameProps(
                      item?.id || '',
                      [
                        'Title',
                        'PreTitle',
                        'SelectedItems',
                        'Image',
                        'Mobile Image',
                        'Description',
                      ],
                      'Tab-' + index,
                      'Edit Tab-' + index
                    )}
                  >
                    <Text
                      tag="div"
                      className={`${
                        device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''
                      }`}
                      field={item.fields.PreTitle}
                    />
                  </EditFrame>
                </BtnTab>
              ))}
            </div>
            <div data-aos="fade-up" className="TabbedCarousel_mainSwiper lg:w-[calc(100%-232px)]">
              <Swiper
                ref={mainSwiperRef}
                className="w-full"
                effect={'fade'}
                navigation={false}
                slidesPerView={1}
                autoplay={{
                  delay: inExperienceEditor ? 1000000 : duration,
                  disableOnInteraction: false,
                }}
                onActiveIndexChange={(swiper) => {
                  set_activeTab(swiper.activeIndex);
                }}
                modules={[EffectFade, Autoplay]}
                speed={1000}
                direction="horizontal"
                dir={isRtl ? 'rtl' : 'ltr'}
              >
                {fields.SelectedItems?.map((item) => (
                  <SwiperSlide key={item.id} className="flex flex-col gap-10">
                    <div className="hidden lg:block w-[150px] h-1 bg-taziz-blue rounded-full">
                      <ProgressBar index={activeTab} />
                    </div>
                    <Text tag="h2" className="uppercase" field={item.fields.Title} />
                    <Text tag="p" className="typo-p-large" field={item.fields?.Description} />
                    {item.fields?.SelectedItems?.map((row, i) => (
                      <div key={i} className="flex flex-col gap-4">
                        <EditFrame
                          {...getEditFrameProps(
                            row?.id || '',
                            ['Title', 'SelectedItems'],
                            'Row -Root Tab',
                            'Row -Edit Root Tab'
                          )}
                        >
                          <Text tag="p" field={row.fields.Title} />
                          <div className="flex flex-wrap gap-2">
                            {row.fields?.SelectedItems?.map((tag, j) => (
                              <EditFrame
                                {...getEditFrameProps(
                                  tag?.id || '',
                                  ['Title', 'PreTitle'],
                                  'tag Item -Root Tab' + j,
                                  'tag Item- Root Tab' + j
                                )}
                              >
                                <Badge
                                  key={j}
                                  preTitle={tag.fields?.PreTitle?.value}
                                  title={tag.fields?.Title?.value}
                                />
                              </EditFrame>
                            ))}
                          </div>
                        </EditFrame>
                      </div>
                    ))}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'TabbedCarouselComponent'} />
  );
};

export default TabbedCarousel;
