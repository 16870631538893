import React from 'react';
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';

const ErrorBlock = ({ fields, params }) => {
    return fields ? (
        <section className={`ErrorBlock relative py-10 lg:py-[120px] ${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'}  ${params?.RemovePaddingFrom === 'top' ? '!pt-0' : params?.RemovePaddingFrom === 'bottom' ? '!pb-0' : params?.RemovePaddingFrom === 'both' ? '!py-0' : ''}`}>
            <div className='container'>
                <div className='max-w-[860px] mx-auto w-full flex flex-col gap-10 lg:gap-20'>
                    <h3 data-aos="fade-up" className='uppercase text-center'>
                        <span><Text field={fields.Title1} /></span>&nbsp;
                        <span className="text-taziz-green"><Text field={fields.Title2} /></span>
                    </h3>
                    <div className='flex flex-col items-center gap-12'>
                        <RichText data-aos="fade-up" field={fields.Description} className="typo-p-large flex flex-col items-center" />
                        {
                            fields.CTALink?.value?.href &&
                            <div data-aos="fade-up">
                                <Link field={fields.CTALink}>
                                    <BtnStandard type="PrimaryBlue">
                                        {fields.CTALink?.value?.text}
                                        <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
                                    </BtnStandard>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <PlaceholderComponent title={'ErrorBlockComponent'} />
    );
};

export default ErrorBlock;