export function formatTimestamp(timestamp, language) {
  const date = new Date(timestamp);

  const isArabic = language !== 'en';

  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  if (isArabic) {
    const day = date.toLocaleDateString('en-GB', { day: '2-digit' });
    const year = date.toLocaleDateString('en-GB', { year: 'numeric' });

    const month = date.toLocaleDateString('ar-EG', { month: 'short' });

    return `${day} ${month} ${year}`;
  } else {
    return date.toLocaleDateString('en-GB', options).replace(',', '');
  }
}
