import React from 'react'

const IconQuote = ({ className }) => {
    return (
        <svg className={className ? className : 'w-[84px] h-[58px] text-taziz-blue'} xmlns="http://www.w3.org/2000/svg" width="84" height="58" viewBox="0 0 84 58" fill="none">
            <path d="M77.0824 -1.76099H59.2941L47.4353 21.9567V57.5331H83.0118V21.9567H65.2235L77.0824 -1.76099ZM29.6471 -1.76099H11.8588L0 21.9567V57.5331H35.5765V21.9567H17.7882L29.6471 -1.76099Z" fill="currentColor" />
        </svg>
    )
}

export default IconQuote