import { configureStore } from '@reduxjs/toolkit';

import LangSlice from './LangSlice';
import ErrorSlice from './ErrorSlice';

export default configureStore({
  reducer: {
    lang: LangSlice,
    errors: ErrorSlice,
  },
});
