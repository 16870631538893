import { gql } from '@apollo/client';

export const GetSearchResult = gql`
  query ($pageSize: Int, $skip: String, $language: String, $keyword: String, $sortDesc: Boolean) {
    extendedsearch(
      first: $pageSize
      after: $skip
      language: $language
      keyword: $keyword
      rootItem: "{73AD2838-D2BF-5DCA-8ED1-3D571980CA0C}"
      index: "taziz_{database}_index"
      sortBy: "Date"
      sortDesc: $sortDesc
      isGlobalSearch: true
      #fieldsEqual: [{ name: "islayout", value: "1" }]
      fieldsEqual: [
        {
          name: "_template"
          value: "in:89c70bc7887c457d894425d6a9a4c8b4,52d930bed0ad4b3c98906f69dfd284ce,3f48043c455843a587eb9c12e36b0880"
        }
      ]
    ) {
      results {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        items {
          item {
            ... on News {
              headline {
                value
              }
              date {
                ... on DateField {
                  value: formattedDateValue(format: "MMMM dd, yyyy")
                }
              }
              name
            }
            ... on GalleryVideoItem {
              videoTitle {
                value
              }
              videoLink {
                ... on LinkField {
                  anchor
                  className
                  href: url
                  text
                  linkType
                  id
                }
              }

              date {
                ... on DateField {
                  value: formattedDateValue(format: "MMMM dd, yyyy")
                }
              }
              name
            }
            ... on ContentPage {
              headline: field(name: "headline") {
                value
              }
            }
            url
            name
          }
        }
      }
    }
  }
`;
