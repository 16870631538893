import React from 'react';
import BtnStandard from '../../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../../Common/icons/IconArrowCircle';

const NoResultsFound = ({ phrases }) => {
  return (
    <div className="flex flex-col items-center gap-10 mt-[8px] md:mt-[-24px]">
      <h3 className="text-center uppercase ">{`${phrases?.['no-results']}`}</h3>
      <BtnStandard type="PrimaryBlue" href="/" onClick={null}>
        <p>{`${phrases?.['back-to-homepage']}`}</p>
        <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
      </BtnStandard>
    </div>
  );
};

export default NoResultsFound;
