import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { COLORS } from '../../../../utils/constants';
import useExperienceEditor from '../../../../hooks/useExperienceEditor';
import CustomImage from '../../../Common/CustomImage';

const SmallPageBanner = ({ fields, params }) => {
  const inExperienceEditor = useExperienceEditor();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // Check if the background is an image URL
  const isImageUrl =
    fields?.Image && fields?.Image?.value && Object.keys(fields?.Image?.value).length > 0;

  // Determine background style
  const backgroundStyle = isImageUrl && {
    background: `linear-gradient(0deg, rgba(0, 26, 112, 0.50) 0%, rgba(0, 26, 112, 0.50) 100%)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -10,
  };

  return fields ? (
    <section
      className={`SmallPageBanner relative md:pt-[167px] md:pb-[80px] pb-[60px] pt-[116px] ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
      style={{
        color: COLORS[params?.TextColor] || '#001A70',
        background: !isImageUrl ? COLORS[params?.BackgroundColor] || '#FFFFFF' : null,
      }}
    >
      {!inExperienceEditor && isImageUrl && (
        <>
          {' '}
          <div style={backgroundStyle} />
          <CustomImage
            isLCP={true}
            field={
              isMobile
                ? fields.MobileImage?.value?.src
                  ? fields?.MobileImage.value
                  : fields?.Image?.value
                : fields?.Image
            }
            className="absolute inset-0 z-[-20] object-cover w-full h-full"
          />
        </>
      )}
      {inExperienceEditor && isImageUrl && (
        <>
          <Image
            field={fields?.Image?.value}
            className="absolute inset-0 z-20 object-cover w-full h-full"
          />
        </>
      )}

      <div className="container relative z-40">
        {!!fields?.Title?.value && (
          <h1 className="uppercase font-[500]">
            <Text field={fields.Title} />
          </h1>
        )}
        {!!fields?.Description?.value && (
          <h3 className="md:max-w-[898px] typo-page-banner font-normal text-start uppercase ">
            <RichText field={fields?.Description} />
          </h3>
        )}
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'SmallPageBannerComponent'} />
  );
};

export default SmallPageBanner;
