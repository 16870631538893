import React from 'react';
import { RichText as RichTextComponent } from '@sitecore-jss/sitecore-jss-react';

const PageBody = ({ fields, params }) => {
  return fields ? (
    <div className="container PageBody py-10 md:py-[120px]">
      <RichTextComponent field={fields?.Description} />
    </div>
  ) : (
    <PlaceholderComponent title={'PageBodyComponent'} />
  );
};

export default PageBody;
