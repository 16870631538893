import React, { useEffect, useRef, useState } from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnTab from '../../../Common/buttons/BtnTab';
import IconCaret from '../../../Common/icons/IconCaret';
import { SwiperSlide, Swiper } from 'swiper/react';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';
import useFontAlignmentFix from '../../../../hooks/useFontAlignmentFix';
// import bgImage from "../../../../assets/images/text-bg.gif"

const SlideItem = ({ item, bgImageUrl }) => {
  const { device } = useFontAlignmentFix();
  return (
    <div className="flex flex-col gap-8 transition-all duration-700 TimelineCarousel_SlideItem lg:gap-10">
      <div
        className={`YearText w-fit text-[80px] lg:text-[160px] font-bold leading-[100%]  flex-none lg:min-h-[120px] ${
          bgImageUrl ? 'bg-cover bg-clip-text text-transparent' : ''
        } uppercase transition-all duration-1000`}
        style={{ backgroundImage: `url('${bgImageUrl}')` }}
      >
        <div className={`${device === 'Windows' ? 'lg:h-[115px]' : ''}`}>
          <Text field={item.fields.TimelineYear} />
        </div>
      </div>
      <RichText
        tag="h4"
        className="uppercase transition-all duration-1000"
        field={item.fields.Title}
      />
      <RichText
        className="transition-all duration-1000 typo-p-large"
        field={item.fields.Description}
      />
    </div>
  );
};

const TimelineCarousel = ({ fields, isRtl, params, rendering }) => {
  const { device } = useFontAlignmentFix();

  const divRef = useRef(null);
  const mobileSwiperRef = useRef('mobileSwiper');
  const desktopSwiperRef = useRef('desktopSwiper');

  const [activeTab, set_activeTab] = useState(0);
  const [paddingTop, set_paddingTop] = useState('120px');

  const handleResize = () => {
    set_paddingTop(divRef.current.clientHeight + (device === 'Windows' ? 240 : 204));
  };

  useEffect(() => {
    mobileSwiperRef.current.swiper.slideTo(activeTab);
    desktopSwiperRef.current.swiper.slideTo(activeTab);
  }, [activeTab]);

  useEffect(() => {
    // Set initial direction
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [device]);

  return fields ? (
    <section
      className={`TimelineCarousel py-10 lg:pt-[120px] lg:pb-[200px] relative ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      }  ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems', 'PreTitle', 'Icon', 'Image'],
          'Timeline -Root Tab',
          'Timeline -Edit Root Tab'
        )}
      >
        <div className="flex flex-col gap-10 lg:gap-[120px]">
          <div ref={divRef} className="container relative z-10 flex flex-col gap-4 lg:gap-10">
            <div data-aos="fade-up" className="flex items-center gap-2">
              <CustomImage field={fields.Icon} className="w-10 h-10" />
              <div className="w-6 lg:w-10 h-[2px] bg-taziz-blue" />
              <Text
                tag="p"
                className="w-[calc(100%-80px)] lg:w-[calc(100%-104px)] uppercase"
                field={fields.PreTitle}
              />
            </div>
            <Text tag="h3" data-aos="fade-up" className="uppercase" field={fields.Title} />
          </div>
          <div
            data-aos="fade-up"
            className="relative z-10 w-full overflow-x-auto px-4 sm:px-6 md:px-12 lg:px-auto lg:!container flex gap-2 lg:flex-col lg:gap-4 hideScrollBar"
          >
            {fields.SelectedItems.map((item, index) => (
              <div
                key={item.id}
                className="w-[121px] lg:w-[126px] min-w-fit flex flex-col gap-4 relative z-10 "
              >
                <EditFrame
                  {...getEditFrameProps(
                    item.id || '',
                    ['Title', 'TimelineYear', 'Description'],
                    'Timeline - Tab-' + index,
                    'Timeline -Edit Tab-' + index
                  )}
                >
                  <BtnTab
                    type="Blue"
                    active={index === activeTab}
                    onClick={() => {
                      set_activeTab(index);
                    }}
                  >
                    <Text
                      tag="div"
                      className={`${device === 'Windows' ? 'mt-1' : 'mb-1'}`}
                      field={item.fields.TimelineYear}
                    />
                    <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
                  </BtnTab>
                </EditFrame>
              </div>
            ))}
          </div>
          <div data-aos="fade-up" className="bottom-0 left-0 w-full lg:absolute lg:h-full">
            <div className="container flex justify-end h-full">
              <div className="w-full lg:invisible lg:w-0 lg:h-0">
                <Swiper
                  ref={mobileSwiperRef}
                  className="w-full"
                  navigation={false}
                  slidesPerView={1}
                  direction="horizontal"
                  onActiveIndexChange={(swiper) => {
                    set_activeTab(swiper.activeIndex);
                  }}
                  dir={isRtl ? 'rtl' : 'ltr'}
                  speed={1000}
                >
                  {fields.SelectedItems.map((item) => (
                    <SwiperSlide key={item.id} className="">
                      <SlideItem item={item} bgImageUrl={fields.Image?.value?.src} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="w-0 h-0 lg:w-[calc(100%-204px)] lg:h-full invisible lg:visible">
                <Swiper
                  ref={desktopSwiperRef}
                  className="w-full h-full"
                  navigation={false}
                  slidesPerView={1}
                  direction="vertical"
                  onActiveIndexChange={(swiper) => {
                    set_activeTab(swiper.activeIndex);
                  }}
                  speed={1000}
                >
                  {fields.SelectedItems.map((item) => (
                    <SwiperSlide
                      key={item.id}
                      className="h-full"
                      style={{ paddingTop: paddingTop }}
                    >
                      <SlideItem item={item} bgImageUrl={fields.Image?.value?.src} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'TimelineCarouselComponent'} />
  );
};

export default TimelineCarousel;
