import React from 'react';

const SingleLineText = ({ props }) => {
  const { onChange } = props;

  const handleChange = (e) => {
    onChange(props.field.valueField.name, e.target.value, props.isValid, []);
  };

  return (
    <div className="flex flex-col w-full lg:w-[calc(50%-12px)]">
      <input
        id={props?.field?.model?.itemId}
        type="text"
        value={props.value}
        onChange={handleChange}
        placeholder={props?.field?.model?.placeholderText}
        className={`w-full  text-[16px]  placeholder:text-muted-blue-gray text-base-darkBlue outline-none rounded-lg p-4 bg-hover-grey ${
          props.errors.length ? 'border-lightRed' : ' '
        }`}
        style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
      />
      {props?.errors?.length > 0 && (
        <div className="mt-2 text-error text-base leading-[19.2px]">
          {props?.errors?.map((item) => item)}
        </div>
      )}
    </div>
  );
};

export default SingleLineText;
