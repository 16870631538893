import React from 'react';
import { RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CustomImage from '../../../Common/CustomImage';
import { formatTimestamp } from '../../../../utils/formatDate';

const NewsHighlight = ({ item, phrases }) => {
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;
  return (
    <a
      href={item?.item?.url}
      className="flex lg:flex-row lg:gap-[40px] flex-col  lg:h-[544px] overflow-hidden bg-white rounded-3xl group   w-full"
    >
      <div className="flex-none lg:w-1/2 lg:max-w-[544px] w-full h-[300px] lg:h-[544px] overflow-hidden">
        <CustomImage
          isLCP={true}
          field={item?.item?.featuredImage}
          className="object-cover w-full h-full duration-300 group-hover:scale-110"
        />
      </div>
      <div className="flex flex-col items-start justify-center lg:pr-[40px] rtl:lg:pr-0 rtl:lg:pl-[40px] lg:py-10 gap-[24px] px-[16px] lg:px-0 py-[24px]">
        <div className="flex items-center gap-1.5 ">
          <p className="uppercase text-taziz-blue typo-p-small-regular">
            {formatTimestamp(item?.item?.date?.value, language)}
          </p>
          {phrases?.['Article'] && <div className="text-taziz-blue">|</div>}
          <div className="uppercase text-taziz-green typo-p-small-regular">
            {phrases?.['Article']}
          </div>
        </div>
        <h4 className="text-taziz-blue line-clamp-2 leading-[130%]">
          <Text field={item?.item?.headline} />
        </h4>
        <p className="text-taziz-blue line-clamp-5 lg:line-clamp-none">
          <RichText field={item?.item?.teaser} />
        </p>
      </div>
    </a>
  );
};

export default NewsHighlight;
