const getEditFrameProps = (
  dataSource,
  fieldsToEdit,
  frameComponentName, 
  tooltip,
) => {
  return {
    dataSource: dataSource
      ? {
          itemId: dataSource,
          // databaseName: 'web',
          // language: 'en', // optional params you can also set for datasource
        }
      : undefined, // datasource will set the item to be edited by edit frame
    buttons: getEditFrameButtons(fieldsToEdit), // add custom editing functionality or edit field sets with buttons
    title: 'Edit ' + (frameComponentName === '' ? '' : frameComponentName),
    tooltip: tooltip === '' ? 'Edit here' : tooltip,
    cssClass: 'jss-edit-frame', // customize edit frame appearance through CSS
    parameters: {}, // set additional parameters when needed
  }
}

const getEditFrameButtons = (fieldsToEdit) => {
  const editFrameButtons = [
    // {
    //   header: 'WebEditButton',
    //   icon: '/~/icon/Office/16x16/document_selection.png',
    //   click: 'javascript:alert("An edit frame button was just clicked!")',
    //   tooltip: 'Doesnt do much, just a web edit button example',
    // },
    {
      header: 'Field Edit Button',
      icon: '/~/icon/Office/16x16/pencil.png',
      fields: fieldsToEdit,
      tooltip: 'Allows you to open field editor for specified fields',
    },
    {
        header: 'Insert New Item',
        icon: '/~/icon/Office/16x16/insert_from_template.png',
        click: 'webedit:new',
        tooltip: 'Insert a new item',
      },
      {
        header: 'Sort content.',
        icon: '/~/icon/Office/16x16/sort_up_down2.png',
        click: 'webedit:sortcontent',
        tooltip: 'Sort the content.',
      },
      {
        header: 'Delete Link',
        icon: '/~/icon/Office/16x16/delete.png',
        click: 'webedit:delete',
        tooltip: 'Delete the item',
      },
  ]

  return editFrameButtons
}

export default getEditFrameProps
