import React from 'react';
import useFontAlignmentFix from '../../hooks/useFontAlignmentFix';

const Badge = ({ preTitle, title, type = 'white' }) => {
  const { device, language } = useFontAlignmentFix();
  const className = {
    white: 'bg-white/20 text-white',
    blue: 'bg-taziz-blue/15 text-taziz-blue',
  };

  return (
    <div
      className={`px-4 py-3 rounded-full  ${
        className[type] ? className[type] : className['white']
      } flex gap-1`}
    >
      <span
        className={` typo-p-x-small inline-block ${
          device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''
        }`}
      >
        {preTitle}
      </span>
      <div
        className={` typo-p-small ${
          device === 'Windows' ? 'mt-1' : language !== 'en' ? 'mb-1' : ''
        }`}
      >
        {title}
      </div>
    </div>
  );
};

export default Badge;
