import React from 'react';
import Skeleton from '../Skeleton';
import IconLoadingImage from '../icons/IconLoadingImage';

const NewsItemSkeleton = () => {
  return (
    <div className="flex flex-col flex-auto w-full md:w-[349px] md:max-w-[349px] overflow-hidden transition-all h-[377px] duration-300 bg-white  group rounded-3xl ">
      <Skeleton className="w-full h-[195px] overflow-hidden flex items-center justify-center relative">
        <IconLoadingImage className={'w-16 h-16 text-gray-100'} />
      </Skeleton>
      <div className="flex flex-col justify-between gap-6 p-6 grow lg:gap-12">
        <Skeleton className={'h-12 w-full'} />

        <div className="flex items-center gap-1.5 typo-p">
          <Skeleton className={'h-3 w-14'} />
          <div className="text-gray-300">|</div>
          <Skeleton className={'h-3 w-14'} />
        </div>
      </div>
    </div>
  );
};

export default NewsItemSkeleton;
