import React from 'react';
import IconClose from '../icons/IconClose';
import IconSearch from '../icons/IconSearch';

const SearchInput = (props) => {
  const { className, placeholder, isMobile, value, onChange, onClickClose, onEnter, onKeyPress } =
    props;

  return (
    <div
      className={`w-[343px] items-stretch shrink-1 flex justify-between overflow-hidden gap-2.5 pr-4 pl-0 rtl:pr-0 rtl:pl-4 ${className}`}
    >
      <div className={`relative flex items-stretch justify-between w-full shrink-1 ${className}`}>
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          className="pl-4 pr-[48px] rtl:pl-[48px] lg:pr-0 bg-white border-none rounded-lg  rtl:pr-4 grow typo-p focus:outline-none placeholder:text-pressed-grey text-taziz-blue"
        />
        <button onClick={onKeyPress} className="relative z-10 py-3">
          <IconSearch className="w-6 h-6 text-taziz-blue absolute xl:hidden rtl:left-[16px] rtl:right-[unset] right-[16px] top-[16px]" />
        </button>
      </div>
      <button onClick={onClickClose} className="py-3">
        <IconClose
          className={`${isMobile ? 'text-white' : 'text-pressed-grey'} w-8 h-8 shrink-0`}
        />
      </button>
    </div>
  );
};

export default SearchInput;
