import React, { useState, useEffect } from 'react';
import { Text, RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import CustomImage from '../../../Common/CustomImage';
import { formatTimestamp } from '../../../../utils/formatDate';

const LatestNews = ({ fields, params }) => {
  const [phrases, set_phrases] = useState(null);

  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;
  useEffect(() => {
    if (window?.phrases) set_phrases({ ...window.phrases });
    else set_phrases(null);
  }, []);

  return fields ? (
    <section
      className={`LatestNews relative py-10 lg:py-[120px] flex flex-col gap-10 lg:gap-20 ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      }  ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <div data-aos="fade-up" className="container">
        <div className="flex items-center justify-between">
          <Text tag="h3" field={fields.data.Component.title} />
          {fields?.data?.Component?.link?.href && (
            <div className="hidden lg:block">
              <BtnStandard type="PrimaryBlue" href={fields.data.Component.link.href} onClick={null}>
                {fields.data.Component.link.text}
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </div>
          )}
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="w-full lg:max-w-[1440px] md:ps-12 sm:ps-6 ps-4 lg:px-24 xl:px-[156px] mx-auto"
      >
        <Swiper
          resistance={true}
          centeredSlides={false}
          roundLengths={true}
          breakpoints={{
            0: {
              slidesPerView: 'auto',
              resistance: true,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
              resistance: true,
            },
          }}
        >
          {fields?.data?.extendedsearch?.results?.items?.map((item, index) => (
            <SwiperSlide
              key={index}
              className="h-auto overflow-hidden transition-all duration-300 bg-white me-4 w-fit group rounded-3xl hover:bg-hover-grey"
            >
              <a href={item?.item?.url} className="h-full flex flex-col lg:w-auto w-[324px]">
                <div className="w-full h-[196px] overflow-hidden">
                  <CustomImage
                    field={item.item.featuredImage}
                    className="object-cover w-full h-full duration-300 group-hover:scale-110"
                  />
                </div>
                <div className="flex flex-col justify-between gap-8 p-4 lg:p-6 grow lg:gap-12">
                  <RichText
                    tag="div"
                    className="line-clamp-3 typo-p-large"
                    field={item.item.headline}
                  />
                  <div className="flex items-center gap-1.5 typo-p">
                    <p tag="p" className="uppercase text-taziz-blue">
                      {formatTimestamp(item?.item?.date?.value, language)}
                    </p>
                    {phrases?.['Article'] && <div className="text-taziz-blue">|</div>}
                    <div className="uppercase text-taziz-green">{phrases?.['Article']}</div>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {fields?.data?.Component?.link?.href && (
        <div className="container block lg:hidden">
          <BtnStandard type="PrimaryBlue" href={fields.data.Component.link.href} onClick={null}>
            {fields.data.Component.link.text}
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
        </div>
      )}
    </section>
  ) : (
    <PlaceholderComponent title={'LatestNewsComponent'} />
  );
};

export default LatestNews;
