import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
export default function CustomImage({ field, className, isLCP = false }) {
  const loadingAttr = isLCP ? 'eager' : 'lazy';
  const fetchPriorityAttr = isLCP ? 'high' : 'low';

  return (
    <Image
      field={field}
      className={className}
      loading={loadingAttr}
      fetchpriority={fetchPriorityAttr}
    />
  );
}
