import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import CustomImage from '../../../Common/CustomImage';

const Quote = ({ fields, params }) => {
  return fields ? (
    fields.Image?.value?.src ? (
      <section
        className={`Quote relative flex ${
          params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
        } ${
          params?.ImageAlignment === 'left'
            ? 'lg:flex-row flex-col'
            : 'lg:flex-row-reverse flex-col'
        }`}
      >
        <CustomImage
          data-aos="fade-up"
          field={fields.Image}
          className="object-cover lg:w-[375px] xl:w-[593px] w-full"
        />
        <div
          data-aos="fade-up"
          className="grow flex flex-col lg:gap-10 gap-8 lg:py-[120px] py-10 lg:px-[156px] px-4"
        >
          <CustomImage field={fields.Icon} className="w-[84px]" />
          <RichText tag="h5" field={fields.QuoteText} className="uppercase" />
          <div className="flex flex-col gap-2">
            <Text tag="p" className="typo-p-large !m-0" field={fields.Name} />
            <Text tag="p" className="typo-p" field={fields.Distinctions} />
          </div>
        </div>
      </section>
    ) : (
      <section
        className={`Quote ${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'}`}
      >
        <div className="container">
          <div
            data-aos="fade-up"
            className="w-full flex flex-col lg:gap-10 gap-8 px-4 sm:px-10 md:px-16 lg:px-20 xl:px-[120px] 2xl:px-[160px] py-10 lg:py-20 bg-neutral-grey rounded-2xl lg:rounded-3xl"
          >
            <CustomImage field={fields.Icon} className="w-[84px]" />
            <RichText tag="h5" field={fields.QuoteText} className="uppercase" />
            <div className="flex flex-col gap-2">
              <Text tag="p" className="typo-p-large !m-0" field={fields.Name} />
              <Text tag="p" className="typo-p" field={fields.Distinctions} />
            </div>
          </div>
        </div>
      </section>
    )
  ) : (
    <PlaceholderComponent title={'QuoteComponent'} />
  );
};

export default Quote;
