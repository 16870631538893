import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import IconCaret from '../../../Common/icons/IconCaret';
import IconGlobe from '../../../Common/icons/IconGlobe';
import IconOrbit from '../../../Common/icons/IconOrbit';
import IconHandShake from '../../../Common/icons/IconHandShake';
import IconGear from '../../../Common/icons/IconGear';
import IconChemical from '../../../Common/icons/IconChemical';
import IconLeaf from '../../../Common/icons/IconLeaf';
import IconClock from '../../../Common/icons/IconClock';
import IconLinkedIn from '../../../Common/icons/IconLinkedIn';
import IconX from '../../../Common/icons/IconX';
import IconInstagram from '../../../Common/icons/IconInstagram';
import IconPlay from '../../../Common/icons/IconPlay';
import IconCapacity from '../../../Common/icons/IconCapacity';
import IconLogo from '../../../Common/icons/IconLogo';
import BtnTab from '../../../Common/buttons/BtnTab';
import Badge from '../../../Common/Badge';
import IconQuote from '../../../Common/icons/IconQuote';
import IconUser from '../../../Common/icons/IconUser';
import IconClose from '../../../Common/icons/IconClose';
import IconSpeaker from '../../../Common/icons/IconSpeaker';
import IconFullScreen from '../../../Common/icons/IconFullScreen';
import IconSpeakerOff from '../../../Common/icons/IconSpeakerOff';
import IconPause from '../../../Common/icons/IconPause';
import IconSearch from '../../../Common/icons/IconSearch';
import IconBurger from '../../../Common/icons/IconBurger';
import IconCheck from '../../../Common/icons/IconCheck';
import IconLocationPin from '../../../Common/icons/IconLocationPin';

const ElementItem = ({ html, children }) => {
  return (
    <CopyToClipboard
      text={html}
      onCopy={() => alert(`Copied to clipboard!\n-----------------------------\n${html}`)}
    >
      <button className='transition-all duration-300 border border-transparent hover:border-taziz-blue/20 p-1 rounded' title="Click to copy element">
        {children}
      </button>
    </CopyToClipboard>
  )
}

const Elements = () => {
  return (
    <div className='bg-[#D5DBEF] relative'>
      <div className='container flex flex-col gap-6 py-10'>
        <h3 className='text-center'>Icons & Elements</h3>
        <h5>Icons</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <ElementItem html='<IconArrowCircle className="w-10 h-10 text-taziz-blue" />'>
            <IconArrowCircle className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconCaret className="w-10 h-10 text-taziz-blue" />'>
            <IconCaret className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconGlobe className="w-10 h-10 text-taziz-blue" />'>
            <IconGlobe className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconOrbit className="w-10 h-10 text-taziz-blue" />'>
            <IconOrbit className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconHandShake className="w-10 h-10 text-taziz-blue" />'>
            <IconHandShake className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconGear className="w-10 h-10 text-taziz-blue" />'>
            <IconGear className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconChemical className="w-10 h-10 text-taziz-blue" />'>
            <IconChemical className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconLeaf className="w-10 h-10 text-taziz-blue" />'>
            <IconLeaf className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconClock className="w-10 h-10 text-taziz-blue" />'>
            <IconClock className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconLinkedIn className="w-10 h-10 text-taziz-blue" />'>
            <IconLinkedIn className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconX className="w-10 h-10 text-taziz-blue" />'>
            <IconX className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconInstagram className="w-10 h-10 text-taziz-blue" />'>
            <IconInstagram className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconPlay className="w-10 h-10 text-taziz-blue" />'>
            <IconPlay className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconCapacity className="w-10 h-10 text-taziz-blue" />'>
            <IconCapacity className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconQuote className="w-10 h-10 text-taziz-blue" />'>
            <IconQuote className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconUser className="w-10 h-10 text-taziz-blue" />'>
            <IconUser className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconClose className="w-10 h-10 text-taziz-blue" />'>
            <IconClose className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconSpeaker className="w-10 h-10 text-taziz-blue" />'>
            <IconSpeaker className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconSpeakerOff className="w-10 h-10 text-taziz-blue" />'>
            <IconSpeakerOff className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconFullScreen className="w-10 h-10 text-taziz-blue" />'>
            <IconFullScreen className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconPause className="w-10 h-10 text-taziz-blue" />'>
            <IconPause className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconSearch className="w-10 h-10 text-taziz-blue" />'>
            <IconSearch className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconBurger className="w-10 h-10 text-taziz-blue" />'>
            <IconBurger className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconCheck className="w-10 h-10 text-taziz-blue" />'>
            <IconCheck className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
          <ElementItem html='<IconLocationPin className="w-10 h-10 text-taziz-blue" />'>
            <IconLocationPin className="w-10 h-10 text-taziz-blue" />
          </ElementItem>
        </div>
        <h5>Logos</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <ElementItem html='<IconLogo type="TwoColor" className="w-20 h-20" />'>
            <IconLogo type="TwoColor" className="w-20 h-20" />
          </ElementItem>
          <ElementItem html='<IconLogo type="Blue" className="w-20 h-20" />'>
            <IconLogo type="Blue" className="w-20 h-20" />
          </ElementItem>
          <ElementItem html='<IconLogo className="w-20 h-20" />'>
            <IconLogo className="w-20 h-20" />
          </ElementItem>
        </div>
        <h5>Button Standard</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryWhite" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryWhite" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryWhite" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryWhite" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryBlue" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryBlue" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryBlue" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryBlue" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryWhite" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryWhite" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryWhite" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryWhite" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryBlue" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryBlue" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryBlue" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryBlue" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="NoFillWhite" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillWhite" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="NoFillWhite" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillWhite" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="NoFillBlue" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillBlue" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="NoFillBlue" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillBlue" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
        </div>
        <h5>Button Small</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryWhite" size="small" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryWhite" size="small" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryWhite" size="small" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryWhite" size="small" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryBlue" size="small" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryBlue" size="small" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="PrimaryBlue" size="small" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="PrimaryBlue" size="small" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryWhite" size="small" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryWhite" size="small" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryWhite" size="small" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryWhite" size="small" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryBlue" size="small" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryBlue" size="small" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="SecondaryBlue" size="small" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="SecondaryBlue" size="small" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="NoFillWhite" size="small" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillWhite" size="small" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-4 h-4 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
          <BtnStandard type="NoFillWhite" size="small" href={null} onClick={() => {console.log('Btn clicked')}}>
            TITLE
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillWhite" size="small" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnStandard type="NoFillBlue" size="small" href="#" onClick={null}>
            TITLE
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
          `}
            >
              <BtnStandard type="NoFillBlue" size="small" href="#" onClick={null}>
                TITLE
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </ElementItem>
            <ElementItem
              html={`
            <BtnStandard type="NoFillBlue" size="small" href={null} onClick={() => {console.log('Btn clicked')}}>
              TITLE
            </BtnStandard>
            `}
            >
              <BtnStandard type="NoFillBlue" size="small" href={null} onClick={() => { console.log('Btn clicked') }}>
                TITLE
              </BtnStandard>
            </ElementItem>
          </div>
        </div>
        <h5>Button Icon Only</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <ElementItem
            html={`
          <BtnStandard type="NoFillWhite" href="#" onClick={null}>
            <IconPlay className="w-[80px] h-[80px] lg:w-[120px] lg:h-[120px]" />
          </BtnStandard>
          `}
          >
            <BtnStandard type="NoFillWhite" href="#" onClick={null}>
              <IconPlay className="w-[80px] h-[80px] lg:w-[120px] lg:h-[120px]" />
            </BtnStandard>
          </ElementItem>
          <ElementItem
            html={`
          <BtnStandard type="NoFillWhite" href={null} onClick={() => { console.log('Btn clicked') }}>
            <IconPlay className="w-[80px] h-[80px]" />
          </BtnStandard>
          `}
          >
            <BtnStandard type="NoFillWhite" href={null} onClick={() => { console.log('Btn clicked') }}>
              <IconPlay className="w-[80px] h-[80px]" />
            </BtnStandard>
          </ElementItem>
        </div>
        <h5>Tab Items</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnTab type="White" active={false} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
            <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
          </BtnTab>
          `}
            >
              <BtnTab type="White" active={false} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
                <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
              </BtnTab>
            </ElementItem>
            <ElementItem
              html={`
          <BtnTab type="White" active={false} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
          </BtnTab>
          `}
            >
              <BtnTab type="White" active={false} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
              </BtnTab>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnTab type="White" active={true} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
            <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
          </BtnTab>
          `}
            >
              <BtnTab type="White" active={true} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
                <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
              </BtnTab>
            </ElementItem>
            <ElementItem
              html={`
          <BtnTab type="White" active={true} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
          </BtnTab>
          `}
            >
              <BtnTab type="White" active={true} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
              </BtnTab>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnTab type="Blue" active={false} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
            <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
          </BtnTab>
          `}
            >
              <BtnTab type="Blue" active={false} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
                <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
              </BtnTab>
            </ElementItem>
            <ElementItem
              html={`
          <BtnTab type="Blue" active={false} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
          </BtnTab>
          `}
            >
              <BtnTab type="Blue" active={false} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
              </BtnTab>
            </ElementItem>
          </div>
          <div className='flex flex-col gap-2'>
            <ElementItem
              html={`
          <BtnTab type="Blue" active={true} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
            <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
          </BtnTab>
          `}
            >
              <BtnTab type="Blue" active={true} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
                <IconCaret className="w-6 h-6 rtl:scale-[-1]" />
              </BtnTab>
            </ElementItem>
            <ElementItem
              html={`
          <BtnTab type="Blue" active={true} onClick={() => { console.log('Tab button clicked') }}>
            TITLE
          </BtnTab>
          `}
            >
              <BtnTab type="Blue" active={true} onClick={() => { console.log('Tab button clicked') }}>
                TITLE
              </BtnTab>
            </ElementItem>
          </div>
        </div>
        <h5>Badge</h5>
        <div className='flex flex-wrap gap-4 p-2 rounded'>
          <ElementItem html={`<Badge preTitle={'01'} title="Methanol" />`}>
            <Badge preTitle={'01'} title="Methanol" />
          </ElementItem>
          <ElementItem html={`<Badge title="Methanol" />`}>
            <Badge title="Methanol" />
          </ElementItem>
          <ElementItem html={`<Badge preTitle={'01'} title="Methanol"  type="blue" />`}>
            <Badge preTitle={'01'} title="Methanol" type="blue" />
          </ElementItem>
          <ElementItem html={`<Badge title="Methanol" type="blue" />`}>
            <Badge title="Methanol" type="blue" />
          </ElementItem>
        </div>
      </div>
    </div>
  );
};

export default Elements