import React from 'react';
import Skeleton from '../Skeleton';
import IconPlay from '../icons/IconPlay';

const VideoHighlightSkeleton = () => {
  return (
    <div className="flex gap-[40px] flex-col mb-[16px] w-full relative">
      <Skeleton className="w-full  rounded-3xl aspect-[2/1] max-w-[100%] overflow-hidden group relative lg:h-[636px]">
        <IconPlay className="absolute z-[20] text-white h-14 w-14  lg:h-[120px] lg:w-[120px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
      </Skeleton>

      <Skeleton className={'h-6 w-full'} />
    </div>
  );
};

export default VideoHighlightSkeleton;
