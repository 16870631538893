import React, { useCallback, useRef, useState } from 'react';

import {
  Form,
  createDefaultFieldFactory,
  FieldTypes,
} from '@sitecore-jss/sitecore-jss-react-forms';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../../../temp/config';
import SingleLineText from '../Fields/SingleLineText';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import MultipleLineText from '../Fields/MultipleLineText';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import Telephone from '../Fields/Telephone';
const defaultFieldFactory = createDefaultFieldFactory();

const Forms = ({ sitecoreContext, params, fields }) => {
  const [validCaptcha, setValidCaptcha] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [refreshReCaptcha, set_refreshReCaptcha] = useState(false);
  const [timerId, set_timerId] = useState();
  const submitButtonRef = useRef(null);

  const reloadCaptcha = () => {
    setValidCaptcha(false);
    set_refreshReCaptcha((r) => !r);
  };
  const handleHiddenField = (captcha) => {
    fields?.fields?.forEach((field) => {
      if (field.model.name === 'HiddenField') {
        // hiddenItemTypeId = field.model.fieldTypeItemId
        field.model.value = captcha;
      }
    });
    window.localStorage.setItem('captcha', captcha);
    const elem = document.getElementById('captcha_hidden');
    if (elem) {
      elem.click();
    }
  };

  //  handle Google Captcha validation
  const handleVerify = function (res) {
    setValidCaptcha(res);
    handleHiddenField(res);
  };

  // handle form submission
  const handleSubmit = function () {
    setTimeout(() => {
      setDisableSubmit(true);
    });
    setTimeout(() => {
      setDisableSubmit(false);
      reloadCaptcha();
    }, 2000);
  };

  useCallback(() => {
    clearTimeout(timerId);

    let id = setTimeout(reloadCaptcha, 100000);
    set_timerId(id);
  });

  let hiddenItemTypeId;

  fields?.fields?.forEach((field) => {
    if (field.model.name === 'HiddenField') {
      hiddenItemTypeId = field?.model?.fieldTypeItemId;
      field.model.value = validCaptcha;
    }
  });

  const setCaptchaValue = (props) => {
    let captcha = window.localStorage.getItem('captcha');
    window.localStorage.removeItem('captcha');
    // console.log('----------------- VERIFYING -----------------------\n', captcha);
    props.field.valueField.value = captcha;
    props.field.model.value = captcha;
  };
  defaultFieldFactory.setComponent(hiddenItemTypeId, (props) => {
    return (
      <fieldset className="w-0 h-0 m-0">
        {!validCaptcha && props?.errors?.length > 0 && !props.field.model.value && (
          <div
            className="w-fit absolute left-0 right-0 sm:mx-auto
              bottom-[50px] lg:bottom-[45px]
             text-error text-base leading-[19.2px]"
          >
            {props?.errors?.map((item) => item)}
          </div>
        )}
        <div id="captcha_hidden" onClick={() => setCaptchaValue(props)}>
          <input type="text" className="w-0 h-0" />
        </div>
      </fieldset>
    );
  });

  defaultFieldFactory.setComponent(FieldTypes.SingleLineText, (props) => {
    return <SingleLineText props={props} key={`parent-${props.field.model.itemId}`} />;
  });

  defaultFieldFactory.setComponent(FieldTypes.MultipleLineText, (props) => {
    return <MultipleLineText props={props} key={`parent-${props.field.model.itemId}`} />;
  });

  defaultFieldFactory.setComponent(FieldTypes.Telephone, (props) => (
    <Telephone props={props} key={`parent-${props.field.model.itemId}`} />
  ));

  defaultFieldFactory.setComponent(FieldTypes.Button, (props) => {
    return (
      <>
        <div className="mr-auto ltr:mr-auto rtl:ml-auto rtl:mr-[unset] sm:!mx-auto w-fit mt-[120px] sm:mt-[120px]">
          <BtnStandard
            type="submit"
            buttonRef={submitButtonRef}
            extraClass={
              '!bg-taziz-blue !text-white hover:!bg-taziz-green active:!text-taziz-blue active:!bg-taziz-blue-active'
            }
            onClick={handleSubmit}
          >
            <p>{props.field.model.title}</p>
            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
          </BtnStandard>
        </div>
      </>
    );
  });

  const ErrorComponent = (props) => {
    return (
      <div className="absolute top-full left-[50%] translate-x-[-50%]">
        {props.formErrors?.map((e, index) => {
          return (
            <div key={`formError-${index}`}>
              {!validCaptcha && (
                <div className="mb-4 text-base invalid text-lightRed " id="formError">
                  {e?.split('|')[0]}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  const FieldErrorComponent = function (props) {
    return (
      <div>
        {props.errors?.map((error, index) => (
          <p style={{ color: 'red', fontWeight: 'bold' }} key={index}>
            {error}
          </p>
        ))}
      </div>
    );
  };
  return (
    <section className="relative Form" data-aos="fade-up">
      <div className="container">
        <div className="flex items-center justify-between w-full flex-col lg:py-[80px] gap-[18px] pt-[34px] pb-[40px]">
          <Form
            fieldFactory={defaultFieldFactory}
            form={fields}
            sitecoreApiHost={''}
            sitecoreApiKey={''}
            errorComponent={ErrorComponent}
            className={`relative max-w-[670px] w-full mx-auto flex flex-wrap lg:w-[670px] ${fields?.metadata?.cssClass}`}
            key={fields?.formItemId?.id}
          />
          <div className="absolute bottom-[112px] ltr:left-[24px] rtl:right-[24px] sm:!right-[unset]  lg:bottom-[150px] sm:!left-[50%] sm:translate-x-[-50%]">
            <HCaptcha
              sitekey={sitecoreContext?.siteSettings?.SiteKey?.value}
              onVerify={handleVerify}
              cleanup={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(Forms);
