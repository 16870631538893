import React from 'react';
import { RichText, Link, Image, Text } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import CustomImage from '../../../Common/CustomImage';
import {EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const TwoCards = ({ fields, params, rendering }) => {
  return fields ? (
    <section
      className={`TwoCards relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      } ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
        <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems'],
          'TwoCards -Root Tab',
          'TwoCards -Edit Root Tab'
        )}
      >
      <div className="container grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-10">
        {fields?.SelectedItems?.map((item) => (
            <EditFrame
            {...getEditFrameProps(
              item.id || '',
              ['Title', 'CTALink','Image','Description'],
              'TwoCards -Edit Card' ,
              'TwoCards -Edit Card' 
            )}
          >
          <Link
            field={item?.fields?.CTALink}
            data-aos="fade-up"
            key={item.id}
            className="w-full overflow-hidden bg-white rounded-3xl"
          >
            <div className="h-[310px] flex flex-col justify-center items-center">
              <CustomImage field={item.fields.Image} />
            </div>
            <div className="flex flex-col gap-6 p-6">
              <Text tag="h4" field={item.fields.Title} />
              <RichText tag="p" field={item.fields.Description} />
            </div>
          </Link>
          </EditFrame>
        ))}
      </div>
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'TwoCardsComponent'} />
  );
};

export default TwoCards;
