import React, { useState, useEffect, useRef } from 'react';
import IconClose from '../icons/IconClose';
import BtnStandard from '../buttons/BtnStandard';
import IconSpeaker from '../icons/IconSpeaker';
import IconFullScreen from '../icons/IconFullScreen';
import IconPlay from '../icons/IconPlay';
import IconPause from '../icons/IconPause';
import IconSpeakerOff from '../icons/IconSpeakerOff';
import CustomImage from '../CustomImage';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const VideoPopup = ({ videoLink, closePopup, imgField = null, videoTitle = null }) => {
  const videoRef = useRef(null);

  const [isVideoPaused, set_isVideoPaused] = useState(false);
  const [isMuted, set_isMuted] = useState(false);
  const [progress, set_progress] = useState(0);
  const [currentTime, set_currentTime] = useState(0);
  const [totalTime, set_totalTime] = useState(0);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const secondsToMMSS = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = seconds % 60;
    // Format minutes and seconds to ensure two digits
    return `${String(minutes).padStart(2, '0')}:${String(secondsRemainder).padStart(2, '0')}`;
  };

  const toggleFullScreen = () => {
    const videoElement = videoRef.current || videoMobileRef.current;
    if (!videoElement) return;

    if (isIOS() && videoElement.webkitEnterFullscreen) {
      console.log('Entering fullscreen on iOS');
      videoElement.webkitEnterFullscreen();
    } else {
      console.log('Entering fullscreen on other platforms');
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    }
  };

  const toggleVideo = () => {
    if (isVideoPaused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    set_isVideoPaused(!isVideoPaused);
  };

  const handleOnTimeUpdate = () => {
    set_currentTime(Math.floor(videoRef.current.currentTime));
    set_progress((videoRef.current.currentTime * 100) / videoRef.current.duration);
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoRef.current.currentTime = (videoRef.current.duration / 100) * manualChange;
  };

  const toggleMute = () => {
    set_isMuted((state) => !state);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play();
      set_isVideoPaused(false);
    }
    document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    return () => {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
      if (videoRef.current) {
        videoRef.current?.pause();
      }
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-[10000] w-full h-full bg-black/90">
      <div className="absolute top-0 left-0 w-full h-full" onClick={closePopup} />
      <div className="container relative z-10 h-full py-10 text-white">
        <div className="w-full h-full lg:w-[calc(100%+72px)] lg:-ml-9 flex flex-col gap-6 justify-center">
          <div className="flex justify-end">
            <BtnStandard type="NoFillWhite" onClick={closePopup}>
              <IconClose className="w-8 h-8" />
            </BtnStandard>
          </div>
          <div className="relative overflow-hidden rounded-2xl lg:rounded-3xl">
            {videoLink ? (
              <video
                ref={videoRef}
                onTimeUpdate={handleOnTimeUpdate}
                onLoadedData={() => set_totalTime(Math.floor(videoRef.current.duration))}
                muted={!isMuted}
                playsInline
                className="w-full"
              >
                <source src={videoLink} type="video/mp4" />
              </video>
            ) : (
              <CustomImage field={imgField} className="object-cover w-full h-full " />
            )}
            {videoLink && (
              <div className="absolute left-0 bottom-2 lg:bottom-[38px] w-full  px-[6px] lg:px-[30px]">
                <div className="py-1 px-[10.5px] lg:py-3 lg:px-9 bg-taziz-green-light/90 rounded-[6px] lg:rounded-[20px] overflow-hidden flex items-center gap-2 lg:gap-4">
                  <BtnStandard type="NoFillWhite" onClick={toggleVideo}>
                    {isVideoPaused ? (
                      <IconPlay className="w-5 h-5 lg:w-12 lg:h-12" />
                    ) : (
                      <IconPause className="w-5 h-5 lg:w-12 lg:h-12" />
                    )}
                  </BtnStandard>
                  <div className="relative h-1 bg-white rounded-full grow">
                    <div
                      className="h-full rounded-full bg-taziz-green"
                      style={{ width: `${progress}%` }}
                    />
                    <input
                      className="w-full h-full absolute top-[0px] left-[0px] opacity-0 cursor-pointer"
                      type="range"
                      min="0"
                      max="100"
                      value={progress}
                      onChange={(e) => handleVideoProgress(e)}
                    />
                  </div>
                  <div className="text-[16px] leading-[120%] lg:text-[16px]">
                    {secondsToMMSS(totalTime)}
                  </div>
                  {isMuted ? (
                    <BtnStandard type="NoFillWhite" onClick={toggleMute}>
                      <IconSpeaker className="w-5 h-5 lg:w-6 lg:h-6" />
                    </BtnStandard>
                  ) : (
                    <BtnStandard type="NoFillWhite" onClick={toggleMute}>
                      <IconSpeakerOff className="w-5 h-5 lg:w-6 lg:h-6" />
                    </BtnStandard>
                  )}
                  <BtnStandard type="NoFillWhite" onClick={toggleFullScreen}>
                    <IconFullScreen className="w-5 h-5 lg:w-6 lg:h-6" />
                  </BtnStandard>
                </div>
              </div>
            )}
          </div>
          {videoTitle && (
            <p className="text-white text-start typo-p-large videoTitle">
              <Text field={videoTitle} />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
