import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useEffect, useState } from 'react';

const detectOSAndBrowser = () => {
  if (typeof window !== 'undefined') {
    const platform = window.navigator.platform.toLowerCase();
    const userAgent = window.navigator.userAgent.toLowerCase();

    const os = platform.includes('win') ? 'Windows' : 'macOS';
    const isMozilla = userAgent.includes('firefox');

    return { os, isMozilla };
  }
  return { os: 'Unknown', isMozilla: false };
};

const useFontAlignmentFix = () => {
  const [device, setDevice] = useState('Windows');
  const [isMozilla, setIsMozilla] = useState(false);
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language;

  useEffect(() => {
    const { os, isMozilla: isFirefox } = detectOSAndBrowser();

    if (os === 'macOS' && isFirefox) {
      setDevice('Windows');
    } else {
      setDevice(os);
    }
    setIsMozilla(isFirefox);
  }, [language]);

  const needsFontAlignmentFix =
    (device === 'Windows' && language === 'en') || (device === 'macOS' && language === 'ar');

  const needsLangSwitchAliment =
    (device === 'Windows' && language === 'ar') || (device === 'macOS' && language === 'en');

  return { device, language, isMozilla, needsFontAlignmentFix, needsLangSwitchAliment };
};

export default useFontAlignmentFix;
