import React from 'react'

const IconGlobe = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.05493 11H7.03227C7.21079 8.23716 8.12575 5.58161 9.6685 3.30493C6.13988 4.24871 3.46579 7.2833 3.05493 11ZM12 3.51468C10.2747 5.64943 9.2404 8.26127 9.03706 11H14.9629C14.7596 8.26127 13.7253 5.64943 12 3.51468ZM14.9629 13H9.03706C9.2404 15.7387 10.2747 18.3506 12 20.4853C13.7253 18.3506 14.7596 15.7387 14.9629 13ZM9.6685 20.6951C8.12575 18.4184 7.21079 15.7628 7.03227 13H3.05493C3.46579 16.7167 6.13988 19.7513 9.6685 20.6951ZM14.3315 20.6951C15.8743 18.4184 16.7892 15.7628 16.9677 13H20.9451C20.5342 16.7167 17.8601 19.7513 14.3315 20.6951ZM20.9451 11H16.9677C16.7892 8.23716 15.8743 5.58161 14.3315 3.30493C17.8601 4.24871 20.5342 7.2833 20.9451 11ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="currentColor" />
        </svg>
    )
}

export default IconGlobe