import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';

const DetailsDescription = ({ fields, params }) => {
    return fields ? (
        <section className={`DetailsDescription py-10 lg:py-20 ${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'} ${params?.RemovePaddingFrom === 'top' ? '!pt-0' : params?.RemovePaddingFrom === 'bottom' ? '!pb-0' : params?.RemovePaddingFrom === 'both' ? '!py-0' : ''}`}>
            <div className='container'>
                <div data-aos="fade-up" className='w-full px-4 sm:px-10 md:px-16 lg:px-20 xl:px-[120px] 2xl:px-[160px]'>
                    <RichText className="typo-p-large" field={fields.Description} />
                </div>
            </div>
        </section>
    ) : (
        <PlaceholderComponent title={'DetailsDescriptionComponent'} />
    );
};

export default DetailsDescription;