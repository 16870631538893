import React from 'react';
import { Text, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const LogoCarousel = ({ fields, isRtl, params, rendering }) => {
  return fields ? (
    <section
      className={`LogoCarousel relative py-10 lg:py-[120px] ${
        params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
      }  ${
        params?.RemovePaddingFrom === 'top'
          ? '!pt-0'
          : params?.RemovePaddingFrom === 'bottom'
          ? '!pb-0'
          : params?.RemovePaddingFrom === 'both'
          ? '!py-0'
          : ''
      }`}
    >
      <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems', 'Description', 'PreTitle', 'Icon', 'CTALink'],
          'Cards -Root Tab',
          'Cards -Edit Root Tab'
        )}
      >
        <div className="container flex flex-col gap-8 lg:gap-10">
          <div data-aos="fade-up" className="flex items-center gap-2">
            <CustomImage field={fields.Icon} className="w-10 h-10" />
            <div className="w-6 lg:w-10 h-[2px] bg-taziz-blue" />
            <Text
              tag="p"
              className="w-[calc(100%-80px)] lg:w-[calc(100%-104px)] uppercase"
              field={fields.PreTitle}
            />
          </div>
          <Text tag="h2" data-aos="fade-up" className="uppercase" field={fields.Title} />
          <RichText data-aos="fade-up" className="typo-p-large" field={fields.Description} />
        </div>
        <div
          data-aos="fade-up"
          className="w-full h-[200px] lg:h-[220px] flex items-center linear-timing-swiper"
        >
          <Swiper
            className="max-w-[100vw] w-full h-20"
            navigation={false}
            slidesPerView={'auto'}
            modules={[Autoplay, FreeMode]}
            dir={isRtl ? 'rtl' : 'ltr'}
          >
            <div
              className="myWrapper"
              slot={'wrapper-start'}
              style={{
                animationDuration: `${72 * (fields?.SelectedItems?.length || 0) * 100}ms`,
              }}
            >
              {Array(6)
                .fill(0)
                .map((_, index) =>
                  fields?.SelectedItems?.map((item) => (
                    <EditFrame
                      {...getEditFrameProps(
                        item.id || '',
                        ['Image', 'Link'],
                        'Cards - Card-' + index,
                        'Cards-Edit Card-' + index
                      )}
                    >
                      <SwiperSlide key={`${index}_${item.id}`} className="!w-fit px-5">
                        {!!item?.fields?.Link?.value?.href ? (
                          <Link field={item.fields.Link}>
                            <CustomImage field={item.fields.Image} className="w-auto h-20" />
                          </Link>
                        ) : (
                          <CustomImage field={item.fields.Image} className="w-auto h-20" />
                        )}
                      </SwiperSlide>
                    </EditFrame>
                  ))
                )}
            </div>
            <div
              className="myWrapper"
              slot={'wrapper-end'}
              style={{
                animationDuration: `${72 * (fields?.SelectedItems?.length || 0) * 100}ms`,
              }}
            >
              {Array(6)
                .fill(0)
                .map((_, index) =>
                  fields?.SelectedItems?.map((item) => (
                    <SwiperSlide key={`_${index}_${item.id}`} className="!w-fit px-5">
                      {!!item?.fields?.Link?.value?.href ? (
                        <Link field={item.fields.Link}>
                          <CustomImage field={item.fields.Image} className="w-auto h-20" />
                        </Link>
                      ) : (
                        <CustomImage field={item.fields.Image} className="w-auto h-20" />
                      )}
                    </SwiperSlide>
                  ))
                )}
            </div>
          </Swiper>
        </div>
        {fields.CTALink?.value?.href && (
          <div data-aos="fade-up" className="container">
            <Link field={fields.CTALink}>
              <BtnStandard type="NoFillBlue">
                {fields.CTALink?.value?.text}
                <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
              </BtnStandard>
            </Link>
          </div>
        )}
      </EditFrame>
    </section>
  ) : (
    <PlaceholderComponent title={'LogoCarouselComponent'} />
  );
};

export default LogoCarousel;
