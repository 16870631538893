import React from 'react';
import { RichText, Image, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import IconArrowCircle from '../../../Common/icons/IconArrowCircle';
import CustomImage from '../../../Common/CustomImage';
import { EditFrame } from '@sitecore-jss/sitecore-jss-react';
import getEditFrameProps from '../../../Common/EditFrame/EditFrame';

const CardWithBackgroundTheme = ({ fields, params , rendering }) => {
    return fields ? (
        <section
            className={`CardWithBackgroundTheme relative py-10 lg:py-[120px] ${params?.BackgroundColor === 'grey' ? 'bg-neutral-grey' : 'bg-white'
                } ${params?.RemovePaddingFrom === 'top'
                    ? '!pt-0'
                    : params?.RemovePaddingFrom === 'bottom'
                        ? '!pb-0'
                        : params?.RemovePaddingFrom === 'both'
                            ? '!py-0'
                            : ''
                }`}
        >
             <EditFrame
        {...getEditFrameProps(
          rendering.dataSource || '',
          ['Title', 'SelectedItems'],
          'Cards -Root Tab',
          'Cards -Edit Root Tab'
        )}
      >
            <div className="container flex flex-col gap-10 lg:gap-20">
                {(fields?.Title?.value || fields?.Icon?.value?.src) && <div data-aos="fade-up" className="flex items-center gap-2">
                    <CustomImage field={fields.Icon} className="w-10 h-10" />
                    {fields.Icon?.value?.src && <div className="w-6 lg:w-10 h-[2px] bg-taziz-blue" />}
                    <Text
                        tag="p"
                        className="typo-p w-[calc(100%-80px)] lg:w-[calc(100%-104px)] uppercase"
                        field={fields.Title}
                    />
                </div>}
                <div className="flex flex-col lg:gap-[140px] gap-[120px]">
                    {fields?.SelectedItems?.map((item, index) => (
                        <div
                            data-aos="fade-up"
                            key={index}
                            className={`flex items-center lg:gap-[47px] gap-6 ${item.fields.ImageAlignment.value === 'right'
                                    ? 'lg:flex-row-reverse flex-col'
                                    : 'lg:flex-row flex-col'
                                }`}
                        >
                            <div className="relative lg:w-1/2 w-full lg:pe-[30px] pe-4 lg:pb-[30px] pb-4">
                                <CustomImage
                                    className="relative z-10 w-full h-full lg:rounded-3xl rounded-2xl"
                                    field={item.fields.Image}
                                />
                                <div
                                    className={`z-0 absolute w-full h-full lg:ps-[30px] ps-4 right-0 bottom-0 flex justify-end items-end`}
                                >
                                    <div
                                        className={`w-full aspect-[326/199] lg:rounded-3xl rounded-2xl ${item?.fields?.BackgroundTheme?.value === 'dark'
                                                ? 'bg-dark-green'
                                                : 'bg-taziz-green'
                                            }`}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-6 lg:w-1/2">
                                <div className="flex items-start gap-4">
                                    <CustomImage className="w-10 h-10" field={item.fields.Icon.value} />
                                    <Text tag="h4" className="uppercase" field={item.fields.Title} />
                                </div>
                                <RichText className="typo-p" field={item.fields.Description} />
                                {
                                    item?.fields?.CTALink?.value?.href &&
                                    <Link field={item?.fields?.CTALink} className='w-fit'>
                                        <BtnStandard type="NoFillBlue">
                                            {item.fields.CTALink.value.text}
                                            <IconArrowCircle className="w-5 h-5 rtl:scale-[-1]" />
                                        </BtnStandard>
                                    </Link>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </EditFrame>
        </section>
    ) : (
        <PlaceholderComponent title={'CardWithBackgroundThemeComponent'} />
    );
};

export default CardWithBackgroundTheme;
