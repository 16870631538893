import React from 'react';
import Skeleton from '../Skeleton';
import IconLoadingImage from '../icons/IconLoadingImage';

const NewsHighlightSkeleton = () => {
  return (
    <div className="flex lg:flex-row lg:gap-[40px] flex-col  md:h-[544px] overflow-hidden rounded-3xl group bg-white  mb-[24px] lg:mb-[40px] w-full">
      <Skeleton className="flex-none lg:w-1/2 lg:max-w-[544px] w-full h-[300px] lg:h-[544px] overflow-hidden justify-center items-center flex">
        <IconLoadingImage className={'w-40 h-40 text-gray-100'} />
      </Skeleton>
      <div className="flex flex-col items-start justify-center lg:pr-[40px] rtl:lg:pr-0 rtl:lg:pl-[40px] lg:py-10 gap-[24px] flex-grow px-[16px] lg:px-0 py-[24px]">
        <div className="flex items-center gap-1.5 ">
          <Skeleton className={'h-3 w-14'} />
          <div className="text-gray-300">|</div>
          <Skeleton className={'h-3 w-14'} />
        </div>
        <Skeleton className={'h-12 w-full'} />
        <Skeleton className={'h-36 w-full'} />
      </div>
    </div>
  );
};

export default NewsHighlightSkeleton;
