import React from 'react';
import Skeleton from '../Skeleton';
import IconPlay from '../icons/IconPlay';

const VideoSkeleton = () => {
  return (
    <div className="md:w-[calc(50%-48px)] lg:w-1/3 lg:max-w-[360px] w-full aspect-[16/9] rounded-3xl overflow-hidden group relative h-[212px] grid place-items-center">
      <Skeleton className="w-full h-full overflow-hidden">
        <IconPlay className="absolute z-[20] text-white h-14 w-14 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
      </Skeleton>
    </div>
  );
};

export default VideoSkeleton;
