import React, { useEffect } from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import AOS from 'aos';
import Helmet from 'react-helmet';
import Parser from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { setLang } from './modules/LangSlice';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';
import './assets/style.scss';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes

const Layout = ({ route, context, dictionary }) => {
  const dispatch = useDispatch();
  const _title = route.displayName.replace('-', ' ');
  const _result = _title.replace(/([A-Z])/g, ' $1');
  const fields = route?.fields;
  const finalResult = _result.charAt(0).toUpperCase() + _result.slice(1);
  const ALT_TITLE = finalResult;
  const firstImage = route?.placeholders['jss-main'][0]?.fields?.Image?.value?.src;

  const renderRobot = (noFollow, noIndex) => {
    if (noFollow && noIndex) {
      return 'noindex, nofollow';
    } else if (noFollow && !noIndex) {
      return 'noindex';
    } else if (noIndex && !noFollow) {
      return 'nofollow';
    } else {
      return '';
    }
  };

  if (typeof window !== 'undefined') window.phrases = dictionary;

  useEffect(() => {
    dispatch(setLang({ lang: context.language }));
  }, [context]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.MetaTitle && route.fields.MetaTitle.value) ||
            `Taziz | ${ALT_TITLE}`}
        </title>
        <meta
          name="title"
          content={
            route.fields && route.fields?.MetaTitle?.value
              ? route.fields?.MetaTitle?.value
              : `Taziz | ${ALT_TITLE}`
          }
        />
        <meta
          name="og:title"
          content={fields ? fields?.MetaTitle?.value : `Taziz | ${ALT_TITLE}`}
        />
        <meta name="description" content={fields?.MetaDescription?.value || ALT_TITLE} />
        <meta name="og:description" content={fields?.MetaDescription?.value || ALT_TITLE} />
        <meta name="og:image" content={firstImage || fields?.MetaImage?.value?.src} />
        <meta name="image" content={firstImage || fields?.MetaImage?.value?.src} />
        <meta name="twitter:card" content={firstImage || fields?.MetaImage?.value?.src} />
        <meta
          name="twitter:title"
          content={fields ? fields?.MetaTitle?.value : `Taziz | ${ALT_TITLE}`}
        />
        <meta name="twitter:description" content={fields?.MetaDescription?.value || ALT_TITLE} />

        {/* <link
          rel="canonical"
          href={`https://${context?.siteSettings?.Domain?.value}/${fields?.itemLanguage}${context?.itemPath}`}
        /> */}

        <meta name="tags" content={route.fields?.AdditionalMetaTags?.value} />
        <meta name="keywords" content={route.fields?.MetaKeywords?.value} />
        <meta name="description" content={route.fields?.MetaDescription?.value} />
        {(route?.fields?.NoFollow?.value || route?.fields?.NoIndex?.value) && (
          <meta
            name="robots"
            content={renderRobot(route?.fields?.NoFollow?.value, route?.fields?.NoIndex?.value)}
          />
        )}
        {context?.siteSettings?.Head?.value && (
          <script>{Parser(context?.siteSettings?.Head?.value)}</script>
        )}
      </Helmet>

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {context?.siteSettings?.Body?.value && Parser(context?.siteSettings?.Body?.value)}
      {/* root placeholder for the app, which we add components to using route data */}
      <div className="relative" dir={`${context.language === 'en' ? 'ltr' : 'rtl'}`}>
        <Placeholder
          name="jss-header"
          rendering={route}
          isRtl={context.language === 'en' ? false : true}
        />
        <Placeholder
          name="jss-main"
          rendering={route}
          isRtl={context.language === 'en' ? false : true}
          lang={context.language}
        />
        <Placeholder
          name="jss-footer"
          rendering={route}
          isRtl={context.language === 'en' ? false : true}
        />
      </div>
      {context?.siteSettings?.AfterBody?.value && Parser(context?.siteSettings?.AfterBody?.value)}
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
