import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PlaceholderComponent from '../../../Common/PlaceholderComponent';
import BtnStandard from '../../../Common/buttons/BtnStandard';
import SearchInput from '../../../Common/forms/SearchInput';
import IconSearch from '../../../Common/icons/IconSearch';
import IconBurger from '../../../Common/icons/IconBurger';
import { useLocation } from 'react-router-dom';
import IconClose from '../../../Common/icons/IconClose';
import IconCaret from '../../../Common/icons/IconCaret';
import CustomImage from '../../../Common/CustomImage';
import useFontAlignmentFix from '../../../../hooks/useFontAlignmentFix';

const Header = ({ fields }) => {
  const { sitecoreContext } = useSitecoreContext();
  const { needsLangSwitchAliment, device } = useFontAlignmentFix();
  const location = useLocation();
  const language = sitecoreContext?.language;

  const EnglishLable = fields?.EnglishLanguageLable;
  const ArabicLable = fields?.ArabicLanguageLable;

  const theme = sitecoreContext?.route?.fields?.PageTheme?.value || 'dark';

  const [showSearch, setShowSearch] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 1280px)' });
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (!isMobile) return;
    if (showSearch) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => document.body.classList.remove('overflow-hidden');
  }, [showSearch]);

  const onClickSearch = () => {
    setShowSearch(true);
  };

  const [keyword, setKeyword] = useState('');

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };
  const onKeyPress = (e) => {
    if ((e.type === 'click' || e.key === 'Enter') && keyword.trim() !== '') {
      switch (location.pathname.split('/')[1]) {
        case 'en':
          window.location.href = `/en/search?q=${encodeURIComponent(keyword.trim())}`;
          break;
        case 'ar':
          window.location.href = `/ar/search?q=${encodeURIComponent(keyword.trim())}`;
          break;
        default:
          window.location.href = `/search?q=${encodeURIComponent(keyword.trim())}`;
          break;
      }
    }
  };

  const onClickClose = () => {
    setShowSearch(false);
    setKeyword('');
  };

  const handleBackdropClick = (e) => {
    if (e.target.id === 'modal-backdrop') {
      onClickClose();
    }
  };

  const onOpenMenu = () => {
    setOpenMenu(true);
    document.body.classList.add('overflow-hidden');
  };

  const onCloseMenu = () => {
    setOpenMenu(false);
    document.body.classList.remove('overflow-hidden');
    setActiveL1();
    setActiveL2();
    setL2Lists([]);
    setL3Lists([]);
  };

  const [l2Lists, setL2Lists] = useState([]);
  const [l3Lists, setL3Lists] = useState([]);
  const [activel1, setActiveL1] = useState();
  const [activel2, setActiveL2] = useState();

  const onHoverL1 = (l1) => {
    const selectedL1 = fields?.NavigationItems?.find((item) => item.id === l1.id);
    setActiveL1(selectedL1);
    const l2Contents = selectedL1?.fields?.SelectedLinks;
    setL2Lists(l2Contents);
    setL3Lists([]);
  };

  const onHoverL2 = (l2) => {
    const selectedL1 = fields?.NavigationItems?.find((item) => item.id === activel1?.id);
    const l2Contents = selectedL1?.fields?.SelectedLinks;
    const selectedL2 = l2Contents?.find((item) => item.id === l2.id);
    setActiveL2(selectedL2);
    const l3Contents = selectedL2?.fields?.SelectedLinks;
    setL3Lists(l3Contents);
  };

  const onBackToL1 = () => {
    setL2Lists([]);
  };

  const onBackToL2 = () => {
    setL3Lists([]);
  };

  const isActive = (currentUrl, url) => {
    return currentUrl?.replace('/en', '') === url?.replace('/en', '');
  };

  const switchLanguage = () => {
    return `${language === 'en' ? '/ar' : '/en'}${sitecoreContext?.itemPath}${location.search}`;
  };

  return fields ? (
    <section className={`Header z-50 left-0 top-0 w-full absolute`}>
      <div className="relative">
        <div className="flex items-center justify-between w-full px-4 py-2 xl:px-11 xl:py-9 rounded-2xl">
          <Link field={fields?.Link}>
            <CustomImage
              isLCP={true}
              field={theme === 'dark' ? fields.LightLogo : fields.DarkLogo}
              className="h-[32.2px] md:h-[60px] shrink-0"
            />
          </Link>
          <div className="flex items-center gap-4 xl:gap-6">
            <a
              className={`bg-taziz-blue/10 rounded-full ps-4 pe-3 py-1.5 hover:text-taziz-green duration-300 ${
                theme === 'light' ? 'text-taziz-blue' : 'text-white'
              }`}
              href={switchLanguage()}
            >
              <RichText
                field={language === 'en' ? ArabicLable : EnglishLable}
                className={`uppercase ${
                  needsLangSwitchAliment ? (device === 'Windows' ? 'mt-2' : 'mb-[6px]') : ''
                }`}
              />
            </a>
            <div className={`relative ${isMobile ? '' : showSearch ? 'bg-white rounded-lg' : ''}`}>
              {showSearch ? (
                isMobile ? (
                  <div
                    id="modal-backdrop"
                    className="fixed inset-0 z-50 flex items-start justify-center bg-taziz-blue/40"
                    onClick={handleBackdropClick}
                    // style={{
                    //   background:
                    //     'linear-gradient(0deg, rgba(0, 26, 112, 0.40) 0%, rgba(0, 26, 112, 0.40) 100%)',
                    // }}
                  >
                    <div
                      className="w-full px-5 pt-5 rounded-lg"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <SearchInput
                        isMobile={true}
                        className="!w-full"
                        placeholder="Search"
                        value={keyword}
                        onChange={onChangeKeyword}
                        onKeyPress={onKeyPress}
                        onClickClose={onClickClose}
                      />
                    </div>
                  </div>
                ) : (
                  <SearchInput
                    placeholder="Search"
                    value={keyword}
                    onChange={onChangeKeyword}
                    onKeyPress={onKeyPress}
                    onClickClose={onClickClose}
                  />
                )
              ) : (
                <BtnStandard
                  type={`${theme === 'light' ? 'NoFillBlue' : 'NoFillWhite'}`}
                  onClick={onClickSearch}
                >
                  <IconSearch className="w-6 h-6" />
                </BtnStandard>
              )}
            </div>
            <BtnStandard
              type={`${theme === 'light' ? 'NoFillBlue' : 'NoFillWhite'}`}
              onClick={onOpenMenu}
            >
              <IconBurger className={`w-6 h-6`} />
            </BtnStandard>
          </div>
        </div>
        <div
          className={`absolute overflow-hidden flex flex-col xl:gap-6 gap-10 transition-all duration-300 xl:pt-9 xl:px-11 pt-0 px-0 bg-taziz-blue w-full h-screen left-0 top-0 ${
            openMenu ? 'translate-y-0' : '-translate-y-full'
          }`}
        >
          <div className="flex items-center justify-between px-4 py-2 xl:px-0 xl:py-0">
            <Link field={fields?.Link}>
              <CustomImage field={fields.LightLogo} className="h-[32.2px] md:h-[60px] shrink-0" />
            </Link>
            <BtnStandard type="NoFillWhite" href={null} onClick={onCloseMenu}>
              <IconClose className="w-6 h-6 text-white md:w-8 md:h-8" />
            </BtnStandard>
          </div>
          <div className="flex flex-col justify-between gap-10 pb-8 overflow-hidden grow xl:pb-10">
            <div className="relative grid h-full grid-cols-1 overflow-hidden grow xl:grid-cols-3">
              <div className="flex flex-col justify-between h-full gap-5 pl-6 pr-6 overflow-hidden grow xl:pl-0 xl:pr-0">
                <div className="pl-0 pr-0 overflow-auto grow xl:pl-0 xl:pr-10">
                  <div className="flex flex-col gap-10 xl:gap-14">
                    {fields?.NavigationItems?.map((item, index) => (
                      <div key={index} className="flex items-center justify-between">
                        <Link
                          onMouseEnter={() => (isMobile ? null : onHoverL1(item))}
                          field={item.fields.Link}
                          className="flex items-center group"
                        >
                          <div
                            className={`h-[2px] transition-all duration-500 bg-taziz-green ${
                              isActive(location.pathname, item?.fields?.Link?.value?.href)
                                ? 'w-10 me-6'
                                : 'w-0 me-0'
                            }`}
                          />
                          <h3
                            className={`${
                              isActive(location.pathname, item?.fields?.Link?.value?.href)
                                ? 'text-taziz-green'
                                : 'group-hover:text-projects-blue text-white'
                            } uppercase  duration-300`}
                          >
                            {item?.fields?.Link?.value?.text}
                          </h3>
                        </Link>
                        {item?.fields?.SelectedLinks?.length > 0 && (
                          <BtnStandard
                            extraClass="xl:hidden block"
                            type="NoFillWhite"
                            href={null}
                            onClick={() => onHoverL1(item)}
                          >
                            <IconCaret className="w-10 h-10 rtl:rotate-180" />
                          </BtnStandard>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex gap-6">
                  {fields?.SocialNavigationItems?.map((item, index) => (
                    <Link field={item.fields.Link} key={index} className="relative w-10 h-10 group">
                      <CustomImage
                        field={item.fields.Icon}
                        className="absolute top-0 left-0 w-10 h-10 duration-300 opacity-100 group-hover:opacity-0"
                      ></CustomImage>
                      <CustomImage
                        field={item.fields.HoverIcon}
                        className="absolute top-0 left-0 w-10 h-10 duration-300 opacity-0 group-hover:opacity-100"
                      ></CustomImage>
                    </Link>
                  ))}
                </div>
              </div>
              <div
                className={`flex flex-col xl:px-10 px-6 gap-6 xl:relative absolute left-0 top-0 w-full h-[calc(100%_-_80px)] bg-taziz-blue ${
                  l2Lists?.length
                    ? 'translate-x-0'
                    : 'xl:rtl:translate-x-0 xl:translate-x-0 rtl:-translate-x-full translate-x-full'
                } xl:border-s border-s-0 ${
                  l2Lists?.length
                    ? 'border-neutral-grey/40 opacity-100'
                    : 'border-transparent opacity-0'
                } duration-500`}
              >
                <button onClick={onBackToL1} className="flex items-center gap-4 xl:hidden">
                  <IconCaret className="w-6 h-6 rotate-180 rtl:rotate-0 text-taziz-green" />
                  <div className="text-taziz-green">Back</div>
                </button>
                {l2Lists?.map((item, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <Link
                      onMouseEnter={() => (isMobile ? null : onHoverL2(item))}
                      field={item.fields.Link}
                      className={'flex items-center group'}
                    >
                      <div
                        className={`h-[2px] transition-all duration-500 bg-taziz-green ${
                          isActive(location.pathname, item?.fields?.Link?.value?.href)
                            ? 'w-10 me-6'
                            : 'w-0 me-0'
                        }`}
                      />
                      <div
                        className={`${
                          isActive(location.pathname, item?.fields?.Link?.value?.href)
                            ? 'text-taziz-green'
                            : 'group-hover:text-projects-blue text-white'
                        } typo-p-large uppercase  duration-300`}
                      >
                        {item?.fields?.Link?.value?.text}
                      </div>
                    </Link>
                    {item?.fields?.SelectedLinks?.length > 0 && (
                      <BtnStandard
                        extraClass="xl:hidden block"
                        type="NoFillWhite"
                        href={null}
                        onClick={() => onHoverL2(item)}
                      >
                        <IconCaret className="w-10 h-10 rtl:rotate-180" />
                      </BtnStandard>
                    )}
                  </div>
                ))}
              </div>
              <div
                className={`flex flex-col xl:px-10 px-6 gap-6 xl:relative absolute left-0 top-0 w-full h-[calc(100%_-_80px)] bg-taziz-blue ${
                  l3Lists?.length
                    ? 'translate-x-0'
                    : 'xl:rtl:translate-x-0 xl:translate-x-0 rtl:-translate-x-full translate-x-full'
                } xl:border-s border-s-0 ${
                  l3Lists?.length
                    ? 'border-neutral-grey/40 opacity-100'
                    : 'border-transparent opacity-0'
                } duration-500 `}
              >
                <button onClick={onBackToL2} className="flex items-center gap-4 xl:hidden">
                  <IconCaret className="w-6 h-6 rotate-180 rtl:rotate-0 text-taziz-green" />
                  <div className="text-taziz-green">Back</div>
                </button>
                {l3Lists?.map((item, index) => (
                  <Link
                    field={item.fields.Link}
                    key={index}
                    className={'w-fit flex items-center group'}
                  >
                    <div
                      className={`h-[2px] transition-all duration-500 bg-taziz-green ${
                        isActive(location.pathname, item?.fields?.Link?.value?.href)
                          ? 'w-10 me-6'
                          : 'w-0 me-0'
                      }`}
                    />
                    <div
                      className={`${
                        isActive(location.pathname, item?.fields?.Link?.value?.href)
                          ? 'text-taziz-green'
                          : 'group-hover:text-projects-blue text-white'
                      } typo-p-large uppercase  duration-300`}
                    >
                      {item?.fields?.Link?.value?.text}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <PlaceholderComponent title={'HeaderComponent'} />
  );
};

export default Header;
