import React from 'react'

const IconSpeaker = ({ className }) => {
    return (
        <svg className={className ? className : "w-6 h-6 text-taziz-blue"} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3536_25097)">
                <path d="M5.8582 2.42032C6.26972 2.99238 6.5121 3.69427 6.5121 4.45277C6.5121 5.21127 6.26972 5.91317 5.8582 6.48523M4.69604 3.29137C4.92567 3.62058 5.06034 4.02095 5.06034 4.45277C5.06034 4.8846 4.92567 5.28497 4.69604 5.61418M2.92171 2.23614L2.00255 3.1553C1.95233 3.20552 1.92722 3.23063 1.89792 3.24858C1.87194 3.2645 1.84362 3.27623 1.814 3.28335C1.78058 3.29137 1.74507 3.29137 1.67405 3.29137H1.16964C1.00703 3.29137 0.925722 3.29137 0.863612 3.32301C0.808979 3.35085 0.764561 3.39527 0.736724 3.4499C0.705078 3.51201 0.705078 3.59332 0.705078 3.75593V5.14961C0.705078 5.31223 0.705078 5.39353 0.736724 5.45564C0.764561 5.51027 0.808979 5.55469 0.863612 5.58253C0.925722 5.61418 1.00703 5.61418 1.16964 5.61418H1.67405C1.74507 5.61418 1.78058 5.61418 1.814 5.6222C1.84362 5.62931 1.87194 5.64104 1.89792 5.65696C1.92722 5.67492 1.95233 5.70003 2.00255 5.75024L2.92171 6.6694C3.04609 6.79378 3.10828 6.85597 3.16167 6.86017C3.208 6.86382 3.25327 6.84507 3.28345 6.80973C3.31824 6.769 3.31824 6.68105 3.31824 6.50515V2.40039C3.31824 2.22449 3.31824 2.13654 3.28345 2.09581C3.25327 2.06048 3.208 2.04172 3.16167 2.04537C3.10828 2.04957 3.04609 2.11176 2.92171 2.23614Z" stroke="currentColor" strokeWidth="0.435526" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3536_25097">
                    <rect width="6.96842" height="6.96842" fill="currentColor" transform="translate(0.123047 0.968506)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconSpeaker